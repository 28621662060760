import React, { useContext, useEffect, useRef } from 'react';
import Select, { SelectInstance } from 'react-select';
import { Button } from 'react-bootstrap';
import { AudioContextType, AudioType } from '../../../../dorian-shared/types/audio/audio';
import {
  AudioSelectOption,
  AudioStepAction,
  SFXActionSettings,
} from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext, useUpdateAudio } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { actionSFXOptions, getAudioOptions } from '../utils';
import { auth } from '../../../../components/Auth/Auth';
import notSharedIcon from '../../../../assets/images/eye-black.svg';
import sharedIcon from '../../../../assets/images/eye-green.svg';


const ShareOption = (props: any) => {
  const { data, innerRef, innerProps } = props;

  const user = auth.getUser();
  const updateAudio = useUpdateAudio();

  const [hasBookAccess, setHasBookAccess] = React.useState(data.hasBookAccess);
  const [isUsedInBook, setIsUsedInBook] = React.useState(data.isUsedInBook);

  const handleAudioShare = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    try {
      let result = await updateAudio(data.value, {action: "share"});
      if(data.value === result?.audioId) {
        setHasBookAccess(true);
      }
    } catch (error) {
      console.error('Error in handleAudioShare:', error);
    }
  };
  const handleAudioUnshare = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    try {
      let result = await updateAudio(data.value, {action: "unshare"});
      if(data.value === result?.audioId) {
        setHasBookAccess(false);
      }
    } catch (error) {
      console.error('Error in handleAudioUnshare:', error);
    }
  };

  return (
    <>
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '5px 10px' }}>
      <span style={{maxWidth: 'calc(100% - 1.8em - 15px)'}}>{data.label}</span>
      {data.ownerName && (

        <span className="tooltip-box">
          <span style={{ marginLeft: '10px' }}>
            <img src={hasBookAccess ? sharedIcon : notSharedIcon} alt="Eye icon" style={{ width: '1.8em', height: 'auto' }} />
          </span>

          <div className="tooltip tooltip-left">
            {hasBookAccess === false && (data.ownerId === user?.id || user?.role === 'admin') && (
              <Button
                size="sm"
                style={{ position: 'absolute', top: 0, right: '100%', height: '90%', backgroundColor: '#4de8c3', borderColor: '#4de8c3', color: 'black' }}
                onClick={handleAudioShare}
              >
                Share
              </Button>
            )}
            {hasBookAccess === true && isUsedInBook === false && (data.ownerId === user?.id || user?.role === 'admin') && (
              <Button
                size="sm"
                style={{ position: 'absolute', top: 0, right: '100%', height: '90%', backgroundColor: '#E8584D', borderColor: '#E8584D', color: 'black' }}
                onClick={handleAudioUnshare}
              >
                Unshare
              </Button>
            )}
            <div className="arrow arrow-left" />
            <div className="tooltip-inner">{hasBookAccess ? 'Shared by' : 'Owned by'}<br></br>{data.ownerName}</div>
          </div>
        </span>
      )}
    </div>
    </>
  );
};


export type AudioSFXSettingsProps = {
  value: SFXActionSettings;
  onChange: (value: SFXActionSettings) => void;
  focused?: boolean;
  disabled?: boolean;
};

export function AudioSFXSelect(props: AudioSFXSettingsProps) {
  const {
    value, onChange, focused, disabled,
  } = props;

  const selectRef = useRef<SelectInstance<AudioSelectOption | null>>(null);

  const {
    audios,
  } = useContext<AudioContextType>(AudiosContext);

  useEffect(() => {
    if (focused) {
      selectRef.current?.focus();
    }
  }, [focused]);

  const audioOptions = getAudioOptions(audios, AudioType.SFX);
  const options = [
    {
      label: 'ACTIONS',
      options: actionSFXOptions,
    },
    {
      label: 'SOUND EFFECTS',
      options: audioOptions,
    },
  ];

  const regularValue = audioOptions.find((option) => option.value === value.audioId);
  const currentValue = regularValue
    ?? actionSFXOptions.find((option) => option.value === value.action)
    ?? actionSFXOptions[0];

  return (
    <div className="w-100">
      <Select
        id="audio-sfx-select"
        ref={selectRef}
        options={options}
        value={currentValue}
        onChange={(selectedOption) => {
          const isAction = selectedOption?.action !== AudioStepAction.Play;
          onChange({
            type: AudioType.SFX,
            action: selectedOption?.action ?? AudioStepAction.None,
            audioUrl: selectedOption?.audioUrl ?? '',
            audioId: isAction ? null : selectedOption?.value ?? null,
            audioSettings: null,
          });
        }}
        isDisabled={disabled}
        components={{ Option: ShareOption }}
      />
    </div>
  );
}
