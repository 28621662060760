import React, { InputHTMLAttributes, useCallback } from 'react';
import { FormControlField } from '../../../../../ui/_fields/FormControlField/FormControlField';

export type PriceInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  value: number;
  onChange: (value: number) => void;
};

export function PriceInput({ value, onChange }: PriceInputProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Only allow positive integers
      const price = Math.max(
        0,
        parseInt(event.target.value.replace(/[^0-9]/g, '') || '0', 10),
      );
      onChange(price);
    },
    [onChange],
  );

  return (
    <FormControlField
      type="number"
      id="overrideCurrencyPrice"
      label="Cost (in hearts)"
      placeholder="Price"
      value={value}
      onChange={handleChange}
      containerClassName="my-0 p-0"
      controlClassName="py-0"
      size="sm"
      style={{ height: '1.75em' }}
      error={value <= 0 ? 'Must be a positive number' : ''}
    />
  );
}
