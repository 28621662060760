import { VideoModerationProcessingStatus, VideoProcessingStatus } from '../../dorian-shared/types/media/MediaConfigs';

interface Video {
  processingStatus: VideoProcessingStatus;
  moderationProcessingStatus: VideoModerationProcessingStatus;
}

export const getVideoProcessingStatus = (video: Video) => {
  const hasQVBRFinished = [VideoProcessingStatus.Created, VideoProcessingStatus.Completed]
    .includes(video.processingStatus);
  const hasModerationFinished = [VideoModerationProcessingStatus.Completed]
    .includes(video.moderationProcessingStatus);
  const canBePicked = hasQVBRFinished && hasModerationFinished;
  let isFailed = false;

  let statusText;
  switch (video.moderationProcessingStatus) {
    case VideoModerationProcessingStatus.Processing:
      statusText = 'Video moderation is still processing';
      break;
    case VideoModerationProcessingStatus.Created:
      statusText = 'Video moderation has not started';
      break;
    case VideoModerationProcessingStatus.Failed:
      statusText = 'Video moderation failed';
      isFailed = true;
      break;
    default:
      statusText = null;
  }

  if (!statusText) {
    switch (video.processingStatus) {
      case VideoProcessingStatus.Processing:
        statusText = 'Video is still processing';
        break;
      case VideoProcessingStatus.Failed:
        statusText = 'Video processing failed';
        isFailed = true;
        break;
      default:
        statusText = null;
    }
  }

  return {
    canBePicked,
    statusText,
    isFailed,
  };
};
