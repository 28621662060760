import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { OptionPanel } from './OptionPanel';

export class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsPanel: false,
    };
  }

  onHide = () => {
    this.setState({
      optionsPanel: false,
    });
  };

  render() {
    const {
      user, limits, id, disabled,
    } = this.props;
    const { optionsPanel } = this.state;

    return (
      <>
        <Button
          block
          disabled={(disabled || !id || !user || !limits || limits.length > 0 || false)}
          onClick={() => {
            this.setState({
              optionsPanel: !optionsPanel,
            });
          }}
          variant="primary"
        >
          Options
        </Button>

        <OptionPanel
          show={optionsPanel}
          onHide={this.onHide}
          {...this.props}
        />
      </>
    );
  }
}

Options.defaultProps = {
  limits: {
    answer_text_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    branch_description_max: {
      type: 1, value: '100', max: 1024, min: 1,
    },
    branch_title_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    character_name_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    data_key_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    data_value_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    scene_title_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    step_text_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    story_author_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    story_summary_max: {
      type: 1, value: '100', max: 1024, min: 1,
    },
    story_title_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    tag_value_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    audio_label_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    background_labal_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    character_label_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
    video_label_max: {
      type: 1, value: '100', max: 255, min: 1,
    },
  },
};
