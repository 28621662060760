import React, {
  useRef, useEffect, useState,
} from 'react';
import {
  FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress,
} from 'react-icons/fa';
import ReactPlayer from 'react-player';
import { VideoSettings } from 'dorian-shared/types/video/video';
import styles from './VideoPlayer.module.scss';

interface VideoPlayerProps {
  videoUrl: string;
  videoTitle?: string;
  videoSettings?: VideoSettings;
  isPlaying?: boolean;
  muted?: boolean;
  customControls?: boolean;
}

export function VideoPlayer({
  videoUrl,
  videoTitle,
  videoSettings,
  isPlaying = false,
  muted = false,
  customControls = false,
}: VideoPlayerProps): JSX.Element {
  const playerRef = useRef<ReactPlayer>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = useState(isPlaying);
  const [isMuted, setIsMuted] = useState(muted);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const DEFAULT_VOLUME = 1;

  useEffect(() => {
    setPlaying(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    setIsMuted(muted);
  }, [muted]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
      if (!video) return;

      const isInFullscreen = !!document.fullscreenElement;
      setIsFullscreen(isInFullscreen);
      video.controls = !customControls || isInFullscreen;
    };

    const handleVolumeChange = () => {
      const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
      if (!video) return;
      setIsMuted(video.muted);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);
    const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
    video?.addEventListener('volumechange', handleVolumeChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
      video?.removeEventListener('volumechange', handleVolumeChange);
    };
  }, [customControls]);

  const handlePlayPause = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    playerRef.current?.seekTo(0);
    setPlaying(!playing);
  };

  const handleMuteToggle = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsMuted(!isMuted);
  };

  const handleFullscreenToggle = async (event: React.MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    try {
      if (!isFullscreen) {
        const videoElement = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
        if (videoElement) {
          await videoElement.requestFullscreen();
        }
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      // Silently handle fullscreen errors
    }
  };

  const volume = Number.isFinite(videoSettings?.volume) ? Number(videoSettings?.volume) : DEFAULT_VOLUME;

  return (
    <div
      ref={wrapperRef}
      className={styles.videoPlayerWrapper}
      title={videoTitle}
      onDoubleClick={handleFullscreenToggle}
    >
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        width="100%"
        height="100%"
        playing={playing}
        muted={isMuted}
        volume={volume}
        loop={Boolean(videoSettings?.loop)}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        controls={!customControls}
      />

      {!isFullscreen && customControls && (
        <div className={styles.customControls}>
          <button
            type="button"
            className={styles.controlButton}
            onClick={handlePlayPause}
            title={playing ? 'Pause' : 'Play'}
          >
            {playing ? <FaPause /> : <FaPlay />}
          </button>

          <div className={styles.volumeControl}>
            <button
              type="button"
              className={styles.controlButton}
              onClick={handleMuteToggle}
              title={isMuted ? 'Unmute' : 'Mute'}
            >
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>
          </div>

          <button
            type="button"
            className={styles.controlButton}
            onClick={handleFullscreenToggle}
            title={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
          >
            {isFullscreen ? <FaCompress /> : <FaExpand />}
          </button>
        </div>
      )}
    </div>
  );
}
