import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import classes from './FormControlField.module.scss';

export type FormControlFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> &
  Omit<FormControlProps, 'size'> & {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  controlClassName?: string;
  _ref?: React.Ref<HTMLInputElement>;
  children?: React.ReactNode;
  error?: string;
  size?: 'sm' | 'lg';
};

export function FormControlField({
  id,
  label,
  containerClassName,
  labelClassName,
  controlClassName,
  children,
  _ref,
  error = '',
  size,
  ...rest
}: FormControlFieldProps) {
  return (
    <Form.Group className={classNames(classes.formGroup, containerClassName)}>
      {label && (
        <Form.Label htmlFor={id} className={classNames(classes.label, labelClassName)}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        id={id}
        ref={_ref}
        className={classNames(controlClassName)}
        isInvalid={!!error}
        size={size}
        {...rest}
      >
        {children}
      </Form.Control>
      {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
}
