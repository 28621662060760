import React from 'react';
import { BookLocation } from 'dorian-shared/types/bookLocation/bookLocation';
import noImageIcon from '../../../assets/images/no_image.svg';
import { VideoThumbnail } from '../../common/VideoThumbnail/VideoThumbnail';
import { ImagePopoverPreview } from '../ImagePopoverPreview/ImagePopoverPreview';
import { useScenePreview } from './hooks/useScenePreview';
import styles from './ScenePreviewImg.module.scss';

type ScenePreviewImgProps = {
  scenes: BookLocation[];
  id?: number;
};

export function ScenePreviewImg({ scenes, id }: ScenePreviewImgProps) {
  const {
    hasVideo,
    imageUrl,
    videoUrl,
    title,
    videoSettings,
  } = useScenePreview(scenes, id);

  const hasValidContent = scenes.length > 0 && imageUrl;

  if (!hasValidContent) {
    return null;
  }

  return hasVideo && videoUrl
    ? (
      <div className={styles.ScenePreviewImg}>
        <VideoThumbnail
          videoUrl={videoUrl}
          videoSettings={videoSettings ?? undefined}
          title={title || 'Video'}
          fallbackImageUrl={imageUrl ?? noImageIcon}
        />
      </div>
    )
    : (
      <ImagePopoverPreview
        previewImageUrl={imageUrl}
        delay={{ show: 300, hide: 100 }}
        placement="left-start"
        width="400px"
        label={title}
      >
        <div
          className={styles.ScenePreviewImg}
          style={{ backgroundImage: `url(${imageUrl})` }}
          role="img"
          aria-label={title || 'Scene preview'}
        />
      </ImagePopoverPreview>
    );
}
