import cn from 'classnames';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { auth } from '../../Auth/Auth';
import { SidebarV2 } from '../../uiV2/SideBarV2/SidebarV2';
import { PageHeaderLoged } from '../PageHeader/PageHeaderLoged';
import { Wizard } from '../Wizard';
import { FirstConnection } from './firstConnection';

export class PageWrapper extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      user: auth.getUser(),
      wizardStep: null,
      redirect: false,
      firstConnection: true,
    };
  }

  componentDidMount() {
    const { page } = this.props;
    localStorage.setItem('wizardStep', 0);
    const wizardStep = 0;
    if (page.header.type === 'stories' && wizardStep !== 0) {
      this.setState({
        wizardStep: 1,
      }, () => {
        localStorage.setItem('wizardStep', 1);
      });
    }
    if (page.header.type === 'episodes' && wizardStep !== 0) {
      this.setState({
        wizardStep: 4,
      }, () => {
        localStorage.setItem('wizardStep', 4);
      });
    }
    if (page.header.type === 'branches' && wizardStep !== 0) {
      this.setState({
        wizardStep: 8,
      }, () => {
        localStorage.setItem('wizardStep', 8);
      });
    }

    const { user } = this.state;
    this.setState({
      firstConnection: user && !!user.latestActivity,
    });

    const createTemplate = JSON.parse(localStorage.getItem('createTemplate'));
    if (createTemplate && createTemplate.id) {
      this.setState({
        redirect: `/create/template/${createTemplate.id}`,
      });
    }
  }

  componentDidUpdate() {
    const wizardStep = 0; // JSON.parse(localStorage.getItem(`wizardStep`));
    const { wizardStep: stateWizardStep } = this.state;
    if (wizardStep !== stateWizardStep) {
      this.setState({
        wizardStep,
      });
    }
  }

  wizardUpdate = (val) => {
    this.setState({
      wizardStep: val,
    }, () => {
      localStorage.setItem('wizardStep', val);
    });
  };

  render() {
    const { emailVerifie, children, page } = this.props;
    const { redirect, wizardStep, firstConnection } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }
    return (
      <div
        className="pageWrapperContainer"
        onMouseMove={page.header.wrapperFunc && page.header.wrapperFunc()}
      >
        <SidebarV2
          sidebar={page.sidebar}
          wizard={wizardStep}
          update={this.wizardUpdate}
        />

        <div className="pageWrapperRightContainer">

          <PageHeaderLoged
            pageTitle={page.header.title}
            showSettings={page.header.settings}
            settingsItems={page.header.settingsItems}
            emailVerifie={emailVerifie}
          />

          <div className="pageWrapperContent">
            <div className={cn('main-panel', page.header.mainContentPanel)}>
              <div
                className={cn('content', page.header.mainContentStyle)}
                ref={page.header.contentRef}
              >
                {children}
              </div>

              <Wizard
                {...this.props}
                wizard={wizardStep}
                update={this.wizardUpdate}
              />
            </div>
          </div>
        </div>

        <FirstConnection show={!firstConnection} />
        {wizardStep !== undefined && wizardStep > 1 && (
          <div className="skipWizardBox">
            <Button
              size="sm"
              onClick={() => {
                localStorage.setItem('wizardStep', 0);
                window.location.reload();
              }}
              variant="primary"
            >
              Skip Wizard
            </Button>
          </div>
        )}
      </div>
    );
  }
}
