import {
  useState, useEffect, useMemo, useCallback,
} from 'react';

interface VideoLocation {
  videoUrl: string;
}

interface UseVideoThumbnailProps {
  videoLocation?: VideoLocation;
}

export const useVideoThumbnail = ({ videoLocation }: UseVideoThumbnailProps): string => {
  const [thumbnail, setThumbnail] = useState<string>('');

  const videoUrl = useMemo(() => videoLocation?.videoUrl, [videoLocation?.videoUrl]);

  const cleanup = useCallback((videoElement: HTMLVideoElement) => {
    if (videoElement) {
      videoElement.pause();
      videoElement.removeAttribute('src');
      videoElement.load();
    }
  }, []);

  useEffect(() => {
    if (!videoUrl) return undefined;

    const videoElement = document.createElement('video');

    videoElement.crossOrigin = 'anonymous';
    videoElement.muted = true;
    videoElement.preload = 'auto';

    const handleLoadedData = () => {
      videoElement.play()
        .then(() => {
          setTimeout(() => {
            videoElement.pause();

            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth || 640;
            canvas.height = videoElement.videoHeight || 360;
            const ctx = canvas.getContext('2d');

            if (ctx) {
              ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

              try {
                const dataUrl = canvas.toDataURL('image/jpeg');
                setThumbnail(dataUrl);
              } catch (e) {
                // Silently fallback
              }
            }
            cleanup(videoElement);
          }, 10);
        })
        .catch(() => {
          // Silently handle play errors (common in browsers)
        });
    };

    videoElement.addEventListener('loadeddata', handleLoadedData);
    videoElement.src = videoUrl;
    videoElement.load();

    return () => {
      videoElement.removeEventListener('loadeddata', handleLoadedData);
      cleanup(videoElement);
    };
  }, [videoUrl, cleanup]);

  return useMemo(() => thumbnail, [thumbnail]);
};
