// eslint-disable-next-line no-shadow
export enum BookTypes {
    Original = 'Original',
    Prompt = 'Prompt',
    SpinOff = 'Spin-Off',
    Unknown = 'Unknown',
}

export enum BookGroupId {
    Active = 1,
    Completed = 2,
    Paused = 3,
    Archived = 4,
}

export type BookGroup = {
    id: BookGroupId;
    title: string;
    color: string;
    pos: number;
    isDefault: boolean;
    type: string;
}

export type BookUser = {
    analyticsAccess?: boolean,
    showAnalytic: boolean,
    book_role: string,
    firstName: string,
    fullname: string
    id: number
    lastName: string
    username: string
}

// eslint-disable-next-line no-shadow
export enum BookStyleAlias {
    Arcana = 'arcana_style',
    Dorian = 'dorian_style',
    Default = 'Default',
}

export type BookStyle = {
    'id': number,
    'alias': BookStyleAlias,
    'title': string,
    'createdAt': string,
    'updatedAt': string,
}

export enum SendNotificationsFor {
    Achievement = 'achievement',
}

export type Book = {
    id: number;
    title: string | null;
    groupId: BookGroupId;
    uuid: string;
    original: boolean;
    data: string | null;
    originalBookId: number;
    promptId: number | null;
    liveSessionStreaming: boolean;
    isPrompt: boolean;
    promptActiveContest: boolean;
    promptTrending: boolean;
    promptPriority: number;
    description: string | null;
    sourceBookId: number;
    featured: boolean;
    disabled: boolean;
    isInAppPrompt: boolean;
    streamingRevenueShare: number | null;
    spinOffRevenue: boolean;
    spinOffRevenueShare: number | null;
    exclusiveAccess: boolean;
    prompts_count: number;
    approved_count: number;
    book_role: string;
    users: Array<BookUser>
    sendNotificationsFor: Array<SendNotificationsFor>
    imageAlt: {
        'imageUrl': string;
        'images': {
            'large': string;
            'medium': string;
            'small': string;
            'thumbnail': string;
            'unedited': string;
        };
    };
}
