import React, {
  useState,
} from 'react';
import { Card } from 'react-bootstrap';
import { PageWrapper } from '../../ui/PageWrapper';
import { UploadVideo } from './VideoLibraryContainer/UploadVideo/UploadVideo';
import { VideoLibraryContainer } from './VideoLibraryContainer/VideoLibraryContainer';
import { VideosProvider } from './VideoLibraryContainer/VideosProvider/VideosProvider';

export function VideoLibraryPage(props) {
  const {
    history,
  } = props;

  const [isUploadModalShown, setIsUploadModalShown] = useState(false);

  const showUploadModal = () => setIsUploadModalShown(true);

  const pageLayout = {
    header: {
      title: 'Video',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          href: '/home/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
        },
        {
          title: 'Upload video',
          action: showUploadModal,
          variant: 'primary',
        },
      ],
    },
  };

  const handleUpload = () => {
    setIsUploadModalShown(false);
  };

  const handleCancel = () => {
    setIsUploadModalShown(false);
  };

  return (
    <PageWrapper
      {...props}
      page={pageLayout}
    >
      <VideosProvider>
        <Card className="d-block">
          <Card.Body>
            <Card.Title className="text-center">
              Video
            </Card.Title>
            <VideoLibraryContainer />
          </Card.Body>
        </Card>
        {
          isUploadModalShown && (
            <UploadVideo
              onUpload={handleUpload}
              onCancel={handleCancel}
            />
          )
        }
      </VideosProvider>
    </PageWrapper>
  );
}
