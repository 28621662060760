export const listColumnsSize = {
  title: 6,
  duration: 1,
  type: 2,
  actions: 3,
};
export const listColumnsSizeAdmin = {
  title: 5,
  duration: 1,
  type: 2,
  actions: 2,
  owner: 2,
};
