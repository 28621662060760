import React, { useState } from 'react';
import {
  Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { DebouncedSearch } from '../../../ui/DebouncedSearch/DebouncedSearch';
import { Pagination } from '../../../ui/Pagination/Pagination';
import {
  pageSize,
  useVideos,
} from '../../VideoLibraryPage/VideoLibraryContainer/VideosProvider/useVideos';
import classNames from './VideoPicker.module.scss';
import { VideosBasicWithPick } from './VideosBasicWithPick/VideosBasicWithPick';

export function VideoPicker({
  title,
  initialValue,
  disabled,
  onConfirm,
}) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(initialValue);

  const handleSaveClick = () => {
    setIsModalShown(false);
    onConfirm(selectedVideo);
  };

  const {
    videos,
    isLoading,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
  } = useVideos();

  const handleCancelClick = () => setIsModalShown(false);

  const handleVideoClick = (video) => setSelectedVideo(video);

  const handleVideoDbClick = (video) => {
    setIsModalShown(false);
    onConfirm(video);
  };

  return (
    <>
      <Button
        size="sm"
        className={classNames.buttonExternal}
        disabled={disabled}
        variant={disabled ? 'outline-secondary' : 'secondary'}
        onClick={() => setIsModalShown(true)}
      >
        Choose video
      </Button>
      <Modal
        className={classNames.modal}
        size="lg"
        show={isModalShown}
        onHide={() => setIsModalShown(false)}
        backdrop="static"
      >
        <Modal.Title className={classNames.modalTitle}>
          {title}
        </Modal.Title>
        <Modal.Body className={classNames.modalBody}>
          <Row>
            <Col md={12}>
              {isLoading && (
              <Spinner
                className={classNames.spinner}
                variant="primary"
                animation="border"
                size="lg"
              />
              )}
              <DebouncedSearch
                initialValue={searchTerm}
                onSearchSubmit={handleSearchSubmit}
              />
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
              />
              <VideosBasicWithPick
                videos={videos}
                selectedId={selectedVideo?.id}
                onClick={handleVideoClick}
                onDbClick={handleVideoDbClick}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={classNames.modalButtons}>
            <Button
              className={classNames.button}
              onClick={handleCancelClick}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className={classNames.button}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
