import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAudioPlayer } from '../../AudioLibraryPage/useAudioPlayer';

type AudioPlayProps = {
  url: string;
  disabled?: boolean;
  playLabel?: string;
  stopLabel?: string;
  className?: string;
  settings?: {
    volume?: number;
    loop?: number;
  }
}

export function AudioPlay(props: AudioPlayProps) {
  const {
    url,
    disabled,
    playLabel = 'Listen',
    stopLabel = 'Stop',
    className = 'h-100 w-100',
    settings,
  } = props;

  const [audioUrl, setAudioUrl] = useState<string>(url);

  const {
    isPlayingAudio,
    togglePlayAudio,
    stopPlayingAudio,
  } = useAudioPlayer(audioUrl, settings);

  useEffect(() => {
    stopPlayingAudio();
    setAudioUrl(url);
  }, [stopPlayingAudio, url]);

  return (
    <Button
      variant="secondary"
      size="sm"
      className={className}
      disabled={disabled}
      onClick={togglePlayAudio}
    >
      {isPlayingAudio ? stopLabel : playLabel}
    </Button>
  );
}
