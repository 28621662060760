import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SidebarFoldableGroup } from '../SidebarFoldableGroup/SidebarFoldableGroup';
import { SidebarItemAs, SidebarItemType } from '../types';
import css from './SidebarItem.module.scss';

type SidebarItemProps = {
  item: SidebarItemType;
}

export function SidebarItem(props: SidebarItemProps) {
  const { item } = props;

  const browserHistory = useHistory();

  if (item.visible === false || item.NotAdminPermissions) {
    return null;
  }

  if (item.group) {
    return <SidebarFoldableGroup group={item.group} />;
  }

  if (item.content) {
    return item.content;
  }

  const handleButtonClick = () => {
    if (item.action) {
      item.action();
    }

    if (item.href) {
      browserHistory.push(item.href);
    }
  };

  return (
    <>
      {item.as === SidebarItemAs.Button && (
        <Button
          block
          variant={item.variant}
          onClick={handleButtonClick}
          disabled={item.disabled}
        >
          <div className={css.container}>
            {item.icon && (
            <div className={css.iconContainer}>
              <img src={item.icon} alt={item.title} />
            </div>
            )}
            {item.title}
          </div>
        </Button>
      )}
      {item.as === SidebarItemAs.Group && (
        <div className={css.group}>
          {item.title}
        </div>
      )}
    </>
  );
}
