import React from 'react';
import { PurchaseCurrencyOption } from 'dorian-shared/types/story/Story';

export const currencyAllowedOptions = [
  {
    value: PurchaseCurrencyOption.HeartsOnly,
    label: 'Hearts only',
  },
  {
    value: PurchaseCurrencyOption.SapphiresOnly,
    label: 'Sapphires only',
  },
  {
    value: PurchaseCurrencyOption.HeartsOrSapphires,
    label: 'Hearts + Sapphires',
  },
];

export function CurrencyAllowedOptions() {
  return (
    <>
      {currencyAllowedOptions.map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </>
  );
}
