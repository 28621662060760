export enum SidebarItemsVersion {
  V1 = 'v1',
  V2 = 'v2',
}

export enum SidebarItemAs {
  Button = 'button',
  Line = 'line',
  Link = 'link',
  Space = 'space',
  Group = 'group',
}

export type SidebarItemGroupType = {
  title: string;
  isExpanded?: boolean;
  isExpandable?: boolean;
}

export type SidebarItemType = {
    version?: SidebarItemsVersion;
    content?: React.JSX.Element;
    as: SidebarItemAs;
    icon: string;
    title: string;
    href: string;
    variant: string;
    action?: () => void;
    disabled?: boolean;
    visible?: boolean;
    loading?: boolean;
    activeWizardStep?: number;
    NotAdminPermissions?: boolean;
    group?: SidebarItemGroupType & { items: SidebarItemType[] };
}

export enum SidebarCustomItemVariant {
  Line = 'line',
  Space = 'space',
  SnapToGridCheckbox = 'snapToGridCheckbox',
  ShowGridCheckBox = 'showGridCheckBox',
  Zoom = 'zoom',
  Options = 'Options',
  CreateNodeButton = 'CreateNodeButton',
}
