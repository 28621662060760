import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from '../../../../../assets/images/avatar.png';
import '../../News.scss';
import { NewsItemMenu } from './NewsItemMenu/NewsItemMenu';

export function NewsItem(props) {
  const {
    type, loading, data, update, className, changeGroup, editBook, share, obj: book, groups,
  } = props;

  if (!data) {
    return null;
  }

  if (loading) {
    return (
      <Card className={`NewsItem flex-row flex-wrap card-horizontal ${className}`}>
        <div className="card-horizontal">
          <div className="img-square-wrapper" />
          <Card.Body className="NewsItemBody">
            <Card.Title className="NewsItemTitle" />
            <Card.Text className="NewsItemContent" />
            <Card.Text className="CardAvatar-box" />
          </Card.Body>
        </div>
      </Card>
    );
  }

  const imgUrl = (obj) => {
    if (!obj && data.owner?.image?.imageUrl) {
      return data.owner.image.imageUrl;
    }
    if (obj?.image?.imageUrl) {
      return obj.image.imageUrl;
    }
    return Avatar;
  };

  const propsCanvas = !data
      || !data.cover
      || !data.cover.character
      || data.cover.character.length < 0
      || !data.cover.character.properties
      || data.cover.character.properties.length < 0
    ? null : data.cover.character.properties;
  if (propsCanvas && data.cover.character.expression) {
    propsCanvas.expression = data.cover.character.expression;
  }

  const original = data?.originalBook?.original ?? false;

  return (
    <Card className={`NewsItem flex-row flex-wrap card-horizontal ${className}`}>
      <div className="card-horizontal">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className={`img-square-wrapper ${data.id && !data.cover ? 'link' : ''}`}
          to={!data.promptActiveContest && (
            type !== 'trending' && type !== 'prompts'
          ) ? `/book/${data.id}` : '#'}
        >
          {(data && data.imageAlt && data.imageAlt.imageUrl) && (
          <img
            src={data.imageAlt.imageUrl}
            className="card-img-left"
            alt=""
          />
          )}
          {(data && !data.imageAlt?.imageUrl && data.image?.imageUrl) && (
          <img
            src={data.image.imageUrl}
            className="card-img-left"
            alt=""
          />
          )}
        </Link>
        <Card.Body className="NewsItemBody">
          <Card.Title className="NewsItemTitle" title={data.title}>
            <h5>
              {data.id && !data.promptActiveContest && (type !== 'trending' && type !== 'prompts')
                ? (
                  <Link
                    className="books-list-link"
                    to={`/book/${data.id}`}
                  >
                    {data.title}
                  </Link>
                )
                : data.title}
            </h5>

            <NewsItemMenu
              book={data}
              changeGroup={changeGroup}
              editBook={editBook}
              group={book}
              type={type}
              share={share}
              groups={groups}
            />

          </Card.Title>

          <Card.Text className="CardAvatar-box">
            {data?.owner?.fullname && (
              <span className="CardAvatar">
                <img alt={data.owner.fullname || ''} src={imgUrl()} className="avatar" />
                {data.owner.fullname || ''}
              </span>
            )}
            {(data.users?.length > 0)
              && data.users.map((user) => (
                user?.fullname
                && user?.story_role
                && (user.story_role === 'owner' || user.story_role === 'co-author')
              ) && (
                <span className="CardAvatar" key={user.id}>
                  <img alt={user.fullname || ''} src={imgUrl(user)} className="avatar" />
                  {user.fullname || ''}
                </span>
              ))}
          </Card.Text>

          <Card.Text className="NewsItemContent">
            {data.description && data.description.split('\n').map((item, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={key}>
                {item}
                <br />
              </span>
            ))}
          </Card.Text>
          {(type === 'trending' || (type === 'prompts' && data.promptActiveContest)) && (
            <div>
              <Button
                variant="primary-v2"
                size="sm"
                onClick={() => {
                  update(data.id, original);
                }}
                className="newItemButton"
              >
                {type === 'trending' ? 'Create Story' : 'Enter Now'}
              </Button>
            </div>
          )}
        </Card.Body>
      </div>
    </Card>
  );
}
