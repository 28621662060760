import cs from 'classnames';
import React, { useState } from 'react';
import { Accordion, Col } from 'react-bootstrap';
import { SidebarItem } from '../SidebarItem/SidebarItem';
import { SidebarItemGroupType, SidebarItemType } from '../types';
import css from './SidebarFoldableGroup.module.scss';

type FoldableGroupProps = {
  group: SidebarItemGroupType & { items: SidebarItemType[] }
};

function getSavedGroupStateFromLocalStorage(title: string, isExpanded: boolean, isExpandable: boolean) {
  const groupSate = localStorage.getItem('sidebar-group');

  const savedGroupSate = groupSate ? JSON.parse(groupSate) : {};
  if (!isExpandable) {
    return { savedGroupSate, savedActiveKey: isExpanded ? '0' : '-1' };
  }

  const savedActiveKey = groupSate ? savedGroupSate[title] : '-1';
  return { savedGroupSate, savedActiveKey };
}

function getAccordionIcon(isExpandable: boolean, activeKey: string) {
  if (!isExpandable) {
    return '';
  }
  return activeKey === '0' ? '▽' : '▷';
}

export function SidebarFoldableGroup(props: FoldableGroupProps) {
  const { group } = props;
  const {
    title, items, isExpanded = true, isExpandable = true,
  } = group;

  const { savedActiveKey } = getSavedGroupStateFromLocalStorage(title, isExpanded, isExpandable);

  const [activeKey, setActiveKey] = useState<string>(savedActiveKey);

  const handleAccordionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isExpandable) {
      return;
    }
    const newActiveKey = activeKey === '0' ? '-1' : '0';
    setActiveKey(isExpandable ? newActiveKey : '-1');
    const { savedGroupSate } = getSavedGroupStateFromLocalStorage(title, isExpanded, isExpandable);
    const groupsState = { ...savedGroupSate, [title]: newActiveKey };
    localStorage.setItem('sidebar-group', JSON.stringify(groupsState));
  };

  const foldIcon = getAccordionIcon(isExpandable, activeKey);

  const isGroupContentVisibleItems = items.filter((item) => item.visible !== false);

  if (isGroupContentVisibleItems.length === 0) {
    return null;
  }

  return (
    <Accordion
      defaultActiveKey={activeKey}
      activeKey={activeKey}
    >
      <div>
        <div className={cs(css.group, isExpandable && css.expandable)}>
          <Accordion.Toggle eventKey="0" as="div" onClick={handleAccordionClick}>
            {`${foldIcon} ${title}`}
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey="0">
          <div>
            {items.map((item, index) => (
              <SidebarItem key={index} item={item} />
            ))}
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
}
