import React, { useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import { VideoModal } from 'components/pages/VideoLibraryPage/VideoModal/VideoModal';
import { VideoSettings } from 'dorian-shared/types/video/video';
import noImageIcon from '../../../assets/images/no_image.svg';
import { useVideoThumbnail } from '../../../hooks/useVideoThumbnail';
import styles from './VideoThumbnail.module.scss';

interface VideoThumbnailProps {
  videoUrl: string;
  videoSettings?: VideoSettings;
  title?: string;
  fallbackImageUrl?: string;
  className?: string;
  style?: React.CSSProperties;
  canPlay?: boolean;
}

export function VideoThumbnail({
  videoUrl,
  title = 'Video',
  videoSettings,
  fallbackImageUrl = noImageIcon,
  className = '',
  style = {},
  canPlay = true,
}: VideoThumbnailProps) {
  const [isVideoModalShown, setIsVideoModalShown] = useState(false);
  const videoThumbnail = useVideoThumbnail({
    videoLocation: { videoUrl },
  });

  const defaultStyle: React.CSSProperties = {
    backgroundImage: videoThumbnail
      ? `url(${videoThumbnail})`
      : `url(${fallbackImageUrl})`,
    ...style,
  };

  const handleVideoIconClick = () => {
    if (canPlay) {
      setIsVideoModalShown(true);
    }
  };

  return (
    <div
      className={`${styles.thumbnail} ${className}`}
      style={defaultStyle}
    >
      <button
        type="button"
        className={styles.videoIcon}
        onClick={handleVideoIconClick}
        onDoubleClick={() => setIsVideoModalShown(false)}
      >
        <FaPlayCircle size={50} />
      </button>
      {isVideoModalShown && (
        <VideoModal
          video={{
            title,
            videoUrl,
          }}
          videoSettings={videoSettings}
          isVisible={isVideoModalShown}
          onClose={() => setIsVideoModalShown(false)}
        />
      )}
    </div>
  );
}
