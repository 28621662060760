import React from 'react';
import { CreateNodeButton } from '../../pages/StoryBranches/CreateNodeButton';
import { Options } from '../../pages/StoryBranches/Options';
import { Zoom } from '../../pages/StoryBranches/Zoom';
import { ShowGridCheckbox } from './ShowGridCheckbox';
import { SnapToGridCheckBox } from './SnapToGridCheckBox';
import { SidebarCustomItemVariant } from './types';

export function SidebarCustomItem({ item }) {
  const { variant } = item;

  switch (variant) {
    case SidebarCustomItemVariant.Line:
      return (
        <div className="border-bottom pb-4 mb-4" />
      );
    case 'space':
      return (
        <div className="pb-4" />
      );
    case 'snapToGridCheckbox':
      return (
        <SnapToGridCheckBox item={item} />
      );
    case 'showGridCheckBox':
      return (
        <ShowGridCheckbox item={item} />
      );
    case 'zoom':
      return (
        <Zoom
          branchesScale={item.branchesScale}
          update={item.action}
        />
      );
    case 'Options':
      return (
        <Options
          ref={item.ref}
          id={item.id}
          user={item.user}
          update={item.update}
          limits={item.limits}
          modeEdit={item.modeEdit}
          disabled={item.disabled}
          story={item.story}
        />
      );
    case 'CreateNodeButton':
      return (
        <CreateNodeButton
          title={item.title}
          branchesScale={item.branchesScale}
          addBranchActive={item.addBranchActive}
          modeEdit={item.modeEdit}
          action={item.action}
        />
      );
    default:
      return null;
  }
}
