import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useClientVariables } from 'hooks/useClientVariables';
import ImgArr from '../../../assets/images/arr-002.svg';
import dorianLogo from '../../../assets/images/dorian_logo_text_horizontal.svg';
import ImgHelpIcon from '../../../assets/images/ui/helpIconBlue.svg';
import { BtnMenuCollapsible } from '../../ui/PageHeader/BtnMenuCollapsible';
import { SidebarCustomItem } from './SidebarCustomItem';
import { SidebarItem } from './SidebarItem/SidebarItem';
import { SidebarCustomItemVariant, SidebarItemsVersion, SidebarItemType } from './types';

const environmentName = process.env.REACT_APP_ENVIRONMENTS_NAME || null;
const CONTACT_FORM_URL_FALLBACK = 'https://form.typeform.com/to/ohAZCEm4';

export type SidebarProps = {
  update: (wizard: number) => void;
  sidebar: {
    nav: SidebarItemType[];
  };
  wizard?: number;
}

export function SidebarV2(props: SidebarProps) {
  const { update, sidebar, wizard = 0 } = props;
  const browserHistory = useHistory();
  const [contactFormUrl] = useClientVariables('contact_form_url');

  return (
    <div className="sidebarWrapper">
      <BtnMenuCollapsible />
      <div className="sidebar" data-testid="sidebar" id="sidebar">
        <h5 className="site-logo">
          <Link to="/home">
            <div className="site-logo img-container">
              <img
                src={dorianLogo}
                alt="Dorian"
              />
            </div>
          </Link>
          {environmentName && <i>{environmentName}</i>}
        </h5>
        <div className="sidebar-content">
          {sidebar.nav.map((sidebarItem, i) => {
            const key = `${sidebarItem.variant}-${i}`;
            const version = sidebarItem.version ?? SidebarItemsVersion.V1;

            if (sidebarItem.NotAdminPermissions || sidebarItem.visible === false) {
              return null;
            }

            if (!sidebarItem.variant && !sidebarItem.title) {
              return null;
            }

            if (version === SidebarItemsVersion.V2) {
              return <SidebarItem key={key} item={sidebarItem} />;
            }

            const isCustomItem = Object.values(SidebarCustomItemVariant).includes(sidebarItem.variant as SidebarCustomItemVariant);
            if (isCustomItem) {
              return (<SidebarCustomItem key={key} item={sidebarItem} />);
            }

            if (sidebarItem.action) {
              return (
                <div
                  key={key}
                  className={`my-2 sidebarItem ${wizard && wizard === sidebarItem.activeWizardStep ? 'wizardActive' : ''}`}
                >
                  <Button
                    block
                    key={key}
                    variant={sidebarItem.variant}
                    disabled={wizard ? wizard !== sidebarItem.activeWizardStep : sidebarItem.disabled}
                    onClick={() => {
                      if (wizard > 0) {
                        update(wizard + 1);
                      }
                      sidebarItem.action?.();
                    }}
                  >
                    {sidebarItem.loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {sidebarItem.title}
                  </Button>
                  {wizard && wizard === sidebarItem.activeWizardStep ? (
                    <div className="sidebarArr">
                      <img src={ImgArr} alt="" />
                      <span>click to continue</span>
                    </div>
                  ) : null}
                </div>
              );
            }

            return (
              <div
                key={key}
                className={`my-2 sidebarItem ${wizard && wizard === sidebarItem.activeWizardStep ? 'wizardActive' : ''}`}
              >
                <Button
                  block
                // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  variant={sidebarItem.variant}
                  disabled={wizard ? wizard !== sidebarItem.activeWizardStep : sidebarItem.disabled}
                  onClick={() => browserHistory.push(sidebarItem.href)}
                >
                  {sidebarItem.title}
                </Button>
              </div>
            );
          })}
        </div>
        <div className="sidebarVersion">
          <a href={contactFormUrl as string ?? CONTACT_FORM_URL_FALLBACK} target="_blank" rel="noreferrer">
            <img src={ImgHelpIcon} alt="help icon" />
          </a>
          {/* @ts-ignore */}
          {`Version: ${global.appVersion}`}
        </div>
      </div>
    </div>
  );
}
