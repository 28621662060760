export enum Libx264VideoCompressionPreset {
    ultrafast = 'ultrafast',
    superfast = 'superfast',
    veryfast = 'veryfast',
    faster = 'faster',
    fast = 'fast',
    medium = 'medium',
    slow = 'slow',
    slower = 'slower',
    veryslow = 'veryslow',
    placebo = 'placebo',
}

export type AudioConfig = {
    audio_frequency?: string;
    audio_target_bitrate?: string;
}

export type VideoConfig = {
    audio_frequency?: string;
    audio_target_bitrate?: string;
    video_buffer_size?: string;
    video_minrate?: string;
    video_maxrate?: string;
    video_h264_preset?: string;
    video_framerate?: string;
}

export enum VideoProcessingStatus {
    Created = 0,
    Processing = 1,
    Completed = 2,
    Failed = 3,
}
export enum VideoModerationProcessingStatus {
    Created = 0,
    Processing = 1,
    Completed = 2,
    Failed = 3,
}
