import React, {
  createContext, useContext, useMemo,
} from 'react';
import { useAudios } from './useAudios';

export const AudiosContext = createContext();

const useUploadAudioContext = () => useContext(AudiosContext);

export const useRefetchAudio = () => {
  const { refetchAudio } = useUploadAudioContext();
  return refetchAudio;
};

export const useUpdateAudio = () => {
  const { updateAudio } = useUploadAudioContext();
  return updateAudio;
};

export function AudiosProvider({ children, initialPageSize, authorId, bookId, triggerRefresh }) {
  const {
    audios,
    isLoading,
    refetchAudio,
    updateAudio,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
    currentPageSize,
    setCurrentPageSize,
  } = useAudios(initialPageSize, authorId, bookId, triggerRefresh);

  const contextValue = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      totalCount,
      currentPageSize,
      setCurrentPageSize,

      audios,
      isLoading,
      refetchAudio,
      updateAudio,

      handleSearchSubmit,
      searchTerm,
    }),
    [
      currentPage,
      setCurrentPage,
      totalCount,

      audios,
      isLoading,
      refetchAudio,
      updateAudio,
      
      handleSearchSubmit,
      searchTerm,
    ],
  );

  return (
    <AudiosContext.Provider value={contextValue}>
      { children }
    </AudiosContext.Provider>
  );
}
