import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import imgDelete from '../../../../../assets/images/delete.svg';
import { AudioPreview } from '../AudioPreview/AudioPreview';
import { listColumnsSize, listColumnsSizeAdmin  } from '../Audios/constants';
import { DeleteAudioModal } from '../DeleteAudio/DeleteAudioModal';
import classNames from './Audio.module.scss';

const convertDurationToTime = (value) => moment.utc(value * 1000).format('mm:ss');

export function Audio({
  audio,
  user,
  onChange,
  onTogglePlay,
  isPlaying,
}) {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  const handleAudioDelete = () => {
    setIsDeleteModalShown(false);
    onChange();
  };

  const handleAudioCancel = () => {
    setIsDeleteModalShown(false);
  };

  const handleDeleteClick = () => setIsDeleteModalShown(true);

  let isAdmin = user.role === 'admin';
  let columnSize = isAdmin ? listColumnsSizeAdmin : listColumnsSize;

  return (
    <>
      <Row className={classNames.item}>
        <Col sm={columnSize.title}>
          <Col className={classNames.fieldTitle}>
            {audio.title}
          </Col>
          <Col className={classNames.fieldAlias}>
            {audio.path}
          </Col>
          <Col>
            <AudioPreview url={audio.audioUrl} />
          </Col>
        </Col>
        <Col sm={columnSize.duration} className={classNames.fieldDuration}>
          {convertDurationToTime(audio.duration)}
        </Col>
        <Col sm={columnSize.type} className={classNames.fieldType}>
          {audio.type}
        </Col>
        {isAdmin && (
        <Col sm={columnSize.owner} className={classNames.fieldOwner}>
          {audio.userName}
        </Col>
        )}
        <Col sm={columnSize.actions} className={classNames.fieldActions}>
          <Button
            onClick={handleDeleteClick}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
        </Col>
      </Row>
      { isDeleteModalShown && (
        <DeleteAudioModal
          audio={audio}
          onDelete={handleAudioDelete}
          onCancel={handleAudioCancel}
        />
      )}
    </>
  );
}
