import {
  GoBackProps,
  GoForwardProps,
  IExternalCallbacks,
  OnBeforeTextRenderProps,
  OnNavHistoryStackChangedProps,
} from '@dorian/creation-tools-ui';
import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { BranchStep } from '../../../../dorian-shared/types/branch/BranchStep';
import { Character } from '../../../../dorian-shared/types/character/Character';
import { ChoiceMemory } from '../../../../services/choiceMemory/ChoiceMemory';
import { logger } from '../../../../services/loggerService/loggerService';
import { onBeforeTextRender } from './onbeforeTextRender';
import { onGoBackStack } from './onGoBackStack';
import { onGoForwardStack } from './onGoForwardStack';
import { processAudios, stopAllAudio } from "../../../pages/AudioStepSettingsModal/AudioPlay/AudioController";

let storedStepAudios: any = null;

export function replaySounds() {
  if (storedStepAudios !== null) {
    processAudios(storedStepAudios);
  }
}

export function useChoiceMemoryCallbacks(
  choiceMemory: ChoiceMemory | undefined,
  popupComponent: (text: string, primaryButtonText?: string, iconId?: number) => Promise<void>,
  scrollToBranch?: (branchId: number) => void,
  characters?: Character[],
) {
  const [errorMessage, setErrorMessage] = useState('');

  const navHistoryStacksRef = useRef<OnNavHistoryStackChangedProps['navHistoryStacks']>([]);

  const handleBeforeTextRender = useCallback(
    (props: OnBeforeTextRenderProps) => {
      const { text, step } = props;
      setErrorMessage('');
      try {
        return onBeforeTextRender({
          choiceMemory,
          text,
          step,
          characters,
        });
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.message);
        } else {
          setErrorMessage('Error in onBeforeTextRender');
        }
        return text;
      }
    },
    [characters, choiceMemory],
  );

  const handleGoForward = useCallback(async (props: GoForwardProps): Promise<number | 'goAhead' | 'goBack' | undefined> => {
    const { episodeId, step, stepNum } = props;
    const currentStep = step as BranchStep;
    setErrorMessage('');
    try {
      processAudios(currentStep.stepAudios);
      storedStepAudios = currentStep.stepAudios || null;
      return await onGoForwardStack(choiceMemory, episodeId, currentStep, stepNum, popupComponent);
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage('Error in onGoForwardStack');
      }
      return undefined;
    }
  }, [choiceMemory, popupComponent]);

  const handleGoBack = useCallback((props: GoBackProps): number | 'goAhead' | 'goBack' | undefined => {
    const { historyItem } = props;
    setErrorMessage('');
    try {
      stopAllAudio();
      storedStepAudios = null;
      return onGoBackStack(choiceMemory, historyItem);
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else {
        setErrorMessage('Error in onGoBackStack');
      }
      return undefined;
    }
  }, [choiceMemory]);

  if (errorMessage) {
    logger.error('ERROR:', errorMessage);
  }

  const handleExternalCallbacks = useMemo((): IExternalCallbacks => ({
    onBeforeTextRender: handleBeforeTextRender,
    onNavHistoryStackChanged: async (props: OnNavHistoryStackChangedProps) => {
      const { step, navHistoryStacks } = props;

      scrollToBranch?.(step?.branchId ?? 0);
      const lastStackItem = navHistoryStacks[navHistoryStacks.length - 1];

      if (navHistoryStacksRef.current.length > navHistoryStacks.length) {
        navHistoryStacksRef.current = navHistoryStacks;
        return handleGoBack({ historyItem: lastStackItem });
      }

      if (navHistoryStacksRef.current.length < navHistoryStacks.length) {
        navHistoryStacksRef.current = navHistoryStacks;
        if (!step) {
          return undefined;
        }
        return await handleGoForward({
          episodeId: lastStackItem.episodeId,
          step,
          stepNum: lastStackItem.stepNum,
        });
      }
      return undefined;
    },
  }), [handleBeforeTextRender, handleGoBack, handleGoForward, scrollToBranch]);

  return { handleExternalCallbacks, errorMessage };
}
