import async from 'async';
import React, { Component } from 'react';
import {
  Alert, Button, Container, FormControl, InputGroup, Modal,
} from 'react-bootstrap';
import searchIcon from '../../../assets/images/search.svg';
import academyIcon from '../../../assets/images/ui/academy-icon.svg';
import audioIcon from '../../../assets/images/ui/audio-icon.svg';
import backgroundIcon from '../../../assets/images/ui/background-icon.svg';
import characterIcon from '../../../assets/images/ui/character-icon.svg';
import episodeCreationIcon from '../../../assets/images/ui/create-episode-icon.svg';
import documentIcon from '../../../assets/images/ui/document-icon.svg';
import inviteIcon from '../../../assets/images/ui/inviteIcon.svg';
import performanceIcon from '../../../assets/images/ui/performance-icon.svg';
import playIcon from '../../../assets/images/ui/play-icon.svg';
import videoIcon from '../../../assets/images/ui/video-icon.svg';
import wikiIcon from '../../../assets/images/ui/wiki-icon.svg';
import { api } from '../../api';
import { auth } from '../../Auth/Auth';
import { PageWrapper } from '../../ui/PageWrapper';
import { SidebarItemAs, SidebarItemsVersion } from '../../uiV2/SideBarV2/types';
import {
  isAudiosEnabled,
  isBackgroundsEnabled,
  isCharactersEnabled,
  isVideosEnabled,
} from '../../utils/userActionUtils';
import { AddBook } from '../Books/AddBook';
import { Share } from '../Stories/Share';
import './News.scss';
import { NewsFrame } from './NewsFrame/NewsFrame';
import { NewsFrameTitle } from './NewsFrame/NewsFrameTitle/NewsFrameTitle';
import { RecentEpisode } from './RecentEpisode/RecentEpisode';

export class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      getInviteLinkModal: false,
      editBook: false,
      limits: [],
      groups: [],
      template: null,
      editShare: {},
      editShareActive: false,
      type: '',
      recentEpisode: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    async.parallel({
      info: (callback) => {
        api.get('/v1/user/info')
          .then((res) => {
            callback(null, res.data.user);
          }).catch((error) => {
            callback(error, null);
          });
      },
      groups: (callback) => {
        api.get('/v1/groups/books')
          .then((res) => {
            callback(null, res.data.groups);
          }).catch((error) => {
            callback(error, null);
          });
      },
      limits: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings.limits);
          }).catch((error) => {
            callback(error, null);
          });
      },
      invites: (callback) => {
        api.get('/v1/invites')
          .then((res) => {
            callback(null, res.data.invites);
          }).catch((error) => {
            callback(error, null);
          });
      },
      /*
       Commented out because it's not used
      */
      // unreadCount: (callback) => {
      //   api.get('/v1/blog/countposts')
      //     .then((res) => {
      //       callback(null, res.data.unreadCount);
      //     }).catch((error) => {
      //       callback(error, null);
      //     });
      // },
      recentEpisodes: (callback) => {
        api.get('/v1/recentchapters', { params: { limit: 1 } })
          .then((res) => {
            callback(null, res.data.chapters);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (error, res) => {
      try {
        if (error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            this.errorAlert(error.response.data.error);
          } else if (error && error.message) {
            this.errorAlert(error.message);
          }

          this.setState({
            loading: false,
          });
        } else {
          let pending = 0;
          let grantedCnt = 0;
          res.invites.forEach((obj) => {
            if (obj.granted === false) {
              pending += 1;
            }
            if (obj.granted === true) {
              grantedCnt += 1;
            }
          });
          const recentEpisode = res.recentEpisodes ? res.recentEpisodes[0] : null;
          this.setState({
            limits: res.limits,
            groups: res.groups,
            loading: false,
            // eslint-disable-next-line react/no-unused-state
            granted: grantedCnt,
            // eslint-disable-next-line react/no-unused-state
            pending,
            recentEpisode,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  actionShare = (obj) => {
    this.setState({
      editShare: obj,
      editShareActive: true,
    });
  };

  closeShare = () => {
    this.setState({
      editShare: {},
      editShareActive: false,
    });
    this.loadData();
  };

  changeGroup = (s, g) => {
    api.put(`/v1/books/${s}`, { groupId: g })
      .then(() => {
        this.loadData();
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
      });
  };

  booksShare() {
    const { editShareActive, editShare } = this.state;

    const user = auth.getUser();

    return (
      <Share
        type="book"
        obj={editShare}
        show={editShareActive}
        onHide={this.closeShare}
        user={user}
      />
    );
  }

  render() {
    const {
      loading,
      limits,
      groups,
      copyText,
      template,
      getInviteLinkModal,
      original,
      editBook,
      type,
      recentEpisode,
    } = this.state;

    const user = auth.getUser();

    const page = {
      header: {
        type: 'episodes',
        settings: (user.role === 'admin') && 'admin',
        mainContentStyle: 'pageWrapperContentWithPadding',
      },
      sidebar: {
        nav: [
          {
            title: user.role === 'admin' ? 'All Games' : 'My Games',
            as: SidebarItemAs.Button,
            version: SidebarItemsVersion.V2,
            icon: playIcon,
            variant: 'menu-v2',
            href: '/books',
          },
          {
            title: 'My Library',
            version: SidebarItemsVersion.V2,
            group: {
              title: 'My Library',
              isExpandable: false,
              items: [
                {
                  title: 'Characters',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/character-art-library',
                  variant: 'menu-v2',
                  icon: characterIcon,
                  disabled: user.role !== 'admin' && !isCharactersEnabled(user),
                  visible: user.role === 'admin' || isCharactersEnabled(user),
                },
                {
                  title: 'Backgrounds',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/backgrounds',
                  variant: 'menu-v2',
                  icon: backgroundIcon,
                  disabled: user.role !== 'admin' && !isBackgroundsEnabled(user),
                  visible: user.role === 'admin' || isBackgroundsEnabled(user),
                },
                {
                  title: 'Audio',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/audio-library',
                  variant: 'menu-v2',
                  icon: audioIcon,
                  disabled: user.role !== 'admin' && !isAudiosEnabled(user),
                  visible: user.role === 'admin' || isAudiosEnabled(user),
                },
                {
                  title: 'Video',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/video-library',
                  variant: 'menu-v2',
                  icon: videoIcon,
                  disabled: user.role !== 'admin' && !isVideosEnabled(user),
                  visible: user.role === 'admin' || isVideosEnabled(user),
                },
              ],
            },
          },
          {
            title: 'Tools',
            version: SidebarItemsVersion.V2,
            group: {
              title: 'Tools',
              isExpandable: false,
              items: [
                {
                  title: 'Search',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/search',
                  variant: 'menu-v2',
                  icon: searchIcon,
                  visible: user.role === 'admin',
                },
                {
                  title: 'Game Performance',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/performance',
                  variant: 'menu-v2',
                  icon: performanceIcon,
                },
                {
                  title: 'Invite a Friend',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  action: () => {
                    this.setState({
                      getInviteLinkModal: true,
                    });
                  },
                  variant: 'menu-v2',
                  icon: inviteIcon,
                },
                {
                  title: 'Episode Creation',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/creation',
                  variant: 'menu-v2',
                  icon: episodeCreationIcon,
                  disabled: user.role !== 'admin',
                  visible: user.role === 'admin',
                },
              ],
            },
          },

          {
            title: 'Resources',
            version: SidebarItemsVersion.V2,
            group: {
              title: 'Resources',
              isExpandable: false,
              items: [
                {
                  title: 'Dorian Wiki',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  variant: 'menu-v2',
                  icon: wikiIcon,
                  action: () => window.open('https://doriancreate.notion.site/Welcome-to-Dorian-Creation-132dec4586b24cc38ba5a7bed77bc89d', '_blank'),
                },
                {
                  title: 'Dorian Academy',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  variant: 'menu-v2',
                  icon: academyIcon,
                  action: () => window.open('https://home.dorian.live/dorianacademy', '_blank'),
                },
                {
                  title: 'Terms & Policies',
                  as: SidebarItemAs.Button,
                  version: SidebarItemsVersion.V2,
                  href: '/documents',
                  variant: 'menu-v2',
                  icon: documentIcon,
                },
              ],
            },
          },
        ],
      },
    };

    const linkUrl = document.location.origin.replace('www.', '');

    const handleCopy = () => {
      const linkShare = document.getElementById('linkShare');
      linkShare.select();
      linkShare.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.setState({
        copyText: linkShare.value,
      }, () => {
        setTimeout(() => {
          this.setState({
            copyText: null,
          });
        }, 1000);
      });
    };

    const addBook = () => !!editBook && (
    <AddBook
      show
      limits={limits}
      user={user}
      onHide={() => {
        this.setState({
          template: null,
          editBook: null,
          type: '',
        });
      }}
      type={type}
      original={original}
      book={editBook}
      items={groups}
      template={template}
      update={() => {
        this.setState({
          editBook: null,
          type: '',
        }, () => {
          this.loadData();
        });
      }}
    />
    );

    const handleCreatePromptClick = (val, promptType) => {
      this.setState({
        type: promptType,
        template: val,
        editBook: [],
      });
    };

    const activeStoriesGroup = groups.find((group) => group.type === 'active');

    return (
      <>
        <PageWrapper
          {...this.props}
          page={page}
        >
          <Container
            fluid
            className="NewsItem-Container"
          >
            {
              !loading && activeStoriesGroup && (
                <Container fluid>
                  <NewsFrameTitle
                    title="My Games"
                    linkTitle="See All"
                    linkHref="/books"
                  />
                  <RecentEpisode recentEpisode={recentEpisode} />
                  <NewsFrame
                    key={activeStoriesGroup.id}
                    {...this.props}
                    type="books"
                    obj={activeStoriesGroup}
                    share={this.actionShare}
                    editBook={(book) => this.setState({ editBook: book })}
                    changeGroup={this.changeGroup}
                    groups={groups}
                    limit={4}
                  />
                </Container>
              )
            }

            {
              !loading && (
                <Container fluid>
                  <NewsFrameTitle title="Get Started" subTitle="Use one of our prompts to start a new game." />
                  <NewsFrame
                    {...this.props}
                    type="prompts"
                    limit={4}
                    update={(promptId) => handleCreatePromptClick(promptId, 'prompts')}
                  />
                </Container>
              )
            }
          </Container>
        </PageWrapper>

        {this.booksShare()}
        {addBook()}

        <Modal
          show={getInviteLinkModal}
          onHide={() => {
            this.setState({
              getInviteLinkModal: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="styleV2">Invite a Friend</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Share this link to invite your friends to Dorian!
            {copyText
            && (
            <Alert variant="success">
              copy to clipboard
            </Alert>
            )}

            <InputGroup className="mt-1 mb-3">
              <FormControl
                id="linkShare"
                aria-label="Share"
                defaultValue={`${linkUrl}/invite?token=${btoa(user.id)}`}
              />
              <InputGroup.Append>
                <Button
                  variant="dark"
                  onClick={handleCopy}
                >
                  Copy
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
