import cs from 'classnames';
import React, {
  useState, ChangeEventHandler, FormEventHandler, useCallback, useEffect, useRef,
} from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner, ProgressBar,
} from 'react-bootstrap';
import { v4 as uuidV4 } from 'uuid';
import { useApiService } from '../../../../../contexts/ApiServiceContext/ApiServiceContext';
import { useAsyncOperationState } from '../../../../../dorian-shared/hooks/useAsyncOperationState';
import { type VideoConfig } from '../../../../../dorian-shared/types/media/MediaConfigs';
import { convertVideo, downloadBlob } from '../../../../../services/ffmpeg';
import { api } from '../../../../api';
import { VideoPreview } from '../VideoPreview/VideoPreview';
import { useRefetchVideo } from '../VideosProvider/VideosProvider';
import classNames from './UploadVideo.module.scss';

const acceptFileTypes = '.mp4';
const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const requestConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export function UploadVideo({
  onUpload,
  onCancel,
}: {onUpload: VoidFunction, onCancel: VoidFunction}) {
  const abortController = useRef<AbortController | null>(null);
  const apiService = useApiService();
  const refetchVideo = useRefetchVideo();
  const [loading, setLoading] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);

  // video
  const [isVideoConverting, setIsVideoConverting] = useState(false);
  const [videoConversionProgress, setVideoConversionProgress] = useState(0);
  const [convertedVideo, setConvertedVideo] = useState<Blob | null>(null);
  const [convertedVideoURL, setConvertedVideoURL] = useState<string | null>(null);

  const [videoTitle, setVideoTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [videoConfig, setVideoConfig] = useState<VideoConfig | null>(null);

  const [,
    {
      isLoading,
      setToLoading,
      setToSuccess,
      setToError,
    },
  ] = useAsyncOperationState();
  const loadConfig = useCallback(async () => {
    setToLoading();
    try {
      const _videoConfig = (await apiService.fetchVideoConfig()) ?? {};
      setVideoConfig(_videoConfig);
      setToSuccess();
    } catch (e) {
      setToError();
    }
  }, [apiService, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  const tryPreprocessFile = async (file: File) => {
    if (!videoConfig) {
      return;
    }
    setIsVideoConverting(true);
    setVideoConversionProgress(0);
    abortController.current = new AbortController();
    try {
      const _convertedVideo = await convertVideo(file, videoConfig, (progress) => {
        setVideoConversionProgress(Math.floor(progress * 100));
      }, abortController.current.signal);
      abortController.current = null;
      setConvertedVideo(_convertedVideo);
      setConvertedVideoURL(URL.createObjectURL(_convertedVideo));
    } catch (e) {
      setErrorMessage((e as Error).message);
    }
    setIsVideoConverting(false);
  };

  useEffect(() => () => {
    abortController.current?.abort();
  }, []);

  const onDownloadConvertedVideo = () => {
    if (!convertedVideo) {
      return;
    }

    downloadBlob(convertedVideo, 'converted.mp4');
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!convertedVideo) {
      return;
    }

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      setValidatedForm(true);
      return;
    }

    const formData = new FormData();
    const alias = uuidV4();
    formData.append('video', convertedVideo, `${alias}.mp4`);
    formData.append('alias', alias);
    formData.append('title', videoTitle);

    setLoading(true);

    api.post('/v1/video', formData, requestConfig)
      .then(() => {
        setLoading(false);
        refetchVideo();
        onUpload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const hideErrorMessage = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  // TODO: Ask for format and min/max size file
  const handleSelectFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    hideErrorMessage();
    const file = event?.target?.files?.[0];

    if (!file) {
      setErrorMessage('File not selected.');
      return;
    }

    if (file.type.indexOf('mp4') === -1) {
      setErrorMessage('Available only *.mp4 format');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('Maximum file size is 100MB');
      return;
    }

    if (!videoTitle) {
      setVideoTitle(file.name.replace(/\.[^\\/.]+$/, ''));
    }

    tryPreprocessFile(file);
  };

  return (
    <Modal
      show
      size="lg"
    >
      <Form
        noValidate
        validated={validatedForm}
        className={classNames.form}
        onSubmit={handleFormSubmit}
      >
        <Modal.Header>
          <Modal.Title
            className={classNames.modalTitle}
          >
            Upload video
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={classNames.modalBody}
        >
          <Row>
            <Form.Group
              as={Col}
              sm="12"
              controlId="video-file"
              className={classNames.formGroup}
            >
              <Form.Label className={classNames.formLabel}>
                Video File
              </Form.Label>
              <Form.Control
                required
                name="video-file"
                type="file"
                placeholder="Image"
                accept={acceptFileTypes}
                onChange={handleSelectFile}
              />
              <Form.Control.Feedback type="invalid">
                Please choose a video.
              </Form.Control.Feedback>
              {isVideoConverting && (
                <ProgressBar>
                  <ProgressBar
                    striped
                    variant="success"
                    now={videoConversionProgress}
                    label={`${videoConversionProgress}%`}
                  />
                </ProgressBar>
              )}
              {convertedVideoURL && (
                <VideoPreview
                  url={convertedVideoURL}
                />
              )}
            </Form.Group>

            <Form.Group as={Col} sm="8" controlId="video-title">
              <Form.Label className={classNames.formLabel}>
                Title
              </Form.Label>
              <Form.Control
                required
                value={videoTitle}
                name="video-title"
                type="text"
                size="sm"
                placeholder="Video title"
                pattern="^([A-Za-z]|[0-9]|_|-|)+$"
                onChange={(event) => {
                  hideErrorMessage();
                  setVideoTitle(event.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please use characters A-Z,a-z,0-9,_,-
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              variant="success"
              disabled={!convertedVideo}
              className={classNames.button}
              onClick={onDownloadConvertedVideo}
            >
              Download
            </Button>

            <Button
              type="reset"
              variant="secondary"
              className={classNames.button}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={isVideoConverting}
              className={classNames.button}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Upload
            </Button>
          </div>
          {
            errorMessage && (
              <Col md={12}>
                <Alert variant="danger">
                  {errorMessage}
                </Alert>
              </Col>
            )
          }
        </Modal.Footer>
      </Form>
      {isLoading
          && (
            <>
              <div className={cs({ overlay: isLoading })} />
              <Spinner
                variant="primary"
                animation="border"
                className="spinner"
              />
            </>
          )}
    </Modal>
  );
}
