import cs from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useAchievementsContext } from '../../../../contexts/AchievementsContext/AchievementsContext';
import { useMemoryIconsContext } from '../../../../contexts/MemoryIconsContext/MemoryIconsContext';
import { AchievementRewardType, IAchievementUsedInStory } from '../../../../dorian-shared/types/achievement/Achievement';
import { BadgeWithTooltip } from '../../../ui/AvatarTool/BadgeWithTooltip/BadgeWithTooltip';
import { MemoryValue } from '../../Book/MemoryBank/memoryBankTypes';
import { MemoryIconField } from '../../Book/MemoryBank/MemoryFields/MemoryIconField';
import { IAchievementModal } from '../AchievementsModalTypes';
import { useAchievementsModalContext } from '../context/AchievementsModalContext';
import { getEpisodeTitleIcons } from '../utils';
import { AchievementField } from './AchievementField';
import css from './AchievementRow.module.scss';
import { AchievementWhenField } from './AchievementWhenField/AchievementWhenField';
import { useAchievementWhen } from './AchievementWhenField/useAchievementWhen';
import { BonusEpisodeOptions } from './BonusEpisodeOptions/BonusEpisodeOptions';
import { UnavailableAfterOptions } from './UnavailableAfterOptions/UnavailableAfterOptions';

export type AchievementRowProps = {
  index: number,
  confirmDelete: (
    achievementId: number,
    onConfirm: () => void, onCancel: () => void) => void,
}

const getAchievementUsedTooltipContent = (usedInStory: IAchievementUsedInStory[], isOwnedByUser: boolean) => {
  if (usedInStory.length > 0) {
    const firstItem = usedInStory[0];
    return (
      <>
        <b>Used in:</b>
        {` ${firstItem.Book}`}
        <br />
        <b>Episode:</b>
        {` ${firstItem.Episode}`}
        <br />
        <b>Revision:</b>
        {` ${firstItem.Revision}`}
        <br />
        <b>Node:</b>
        {` ${firstItem.Node}`}
      </>
    );
  }

  if (isOwnedByUser) {
    return (
      <>
        <b>Already granted to users.</b>
        <br />
        Once achieved by a user, an achievement cannot be deleted
      </>
    );
  }

  return '';
};

export function AchievementRow(props: AchievementRowProps) {
  const { index, confirmDelete } = props;

  const { isSendNotifications } = useAchievementsContext();
  const { memoryIcons, createMemoryIcon } = useMemoryIconsContext();
  const { hasValueChanged, episodes } = useAchievementsModalContext();
  const {
    values, setValues, handleChange, errors, setFieldValue,
  } = useFormikContext<IAchievementModal[]>();

  const { value: whenValue } = useAchievementWhen(index);

  const value = values[index];
  const [icon] = useField(`[${index}].icon`);
  const [achievementId] = useField(`[${index}].id`);
  const [isManual, , isManualHelpers] = useField(`[${index}].isManual`);

  const canBeMissedEpisodes = episodes.filter((episode) => Boolean(episode.chapter)); // Exclude bonus episodes
  const currentCanBeMissedEpisode = canBeMissedEpisodes.find((episode) => episode.uuid === value.unavailableAfter);
  const unavailableAfterTitle = currentCanBeMissedEpisode
    ? `${currentCanBeMissedEpisode.chapter}. ${currentCanBeMissedEpisode.title}`
    : '';

  const checkValue = Number.isNaN(Number(value.check.value)) ? '' : value.check.value as MemoryValue;

  const grantDelaySecondsValue = Number.isNaN(Number(value.reward?.delaySec))
    ? 0
    : value.reward?.delaySec ?? 0;

  const rewardEpisodes = episodes.filter((episode) => {
    switch (whenValue?.type) {
      case 'memory': {
        return episode.isBonus || episode.isLocked || value.reward?.contentId === episode.uuid;
      }
      case 'episode': {
        const isLocked = episode.isLocked && !episode.isBonus;
        return isLocked || value.reward?.contentId === episode.uuid;
      }
      default:
        return false;
    }
  });

  const handleDeleteButtonClick = async () => {
    confirmDelete(
      achievementId.value,
      () => {
        setValues((prevValues) => prevValues.filter((v) => v.id !== achievementId.value));
      },
      () => undefined,
    );
  };

  const handleRewardContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setFieldValue(`[${index}].reward.type`, undefined);
    } else {
      setFieldValue(`[${index}].reward.type`, 1);
    }
    handleChange(e);
  };

  const usedInStoryLength = value.usedInStory?.length ?? 0;
  const isDeleteDisabled = usedInStoryLength > 0 || value.isOwnedByUser;

  const isContentIdPresent = rewardEpisodes.some((episode) => episode.uuid === value.reward?.contentId);
  const rewardContentValue = isContentIdPresent ? value.reward?.contentId ?? '' : '';
  if (rewardEpisodes.length && !isContentIdPresent && value.reward?.contentId !== '') {
    // we need to use setFieldValue to trigger validation
    setFieldValue(`[${index}].reward.contentId`, '');
  }

  return (
    <div className={cs(css.row, hasValueChanged(value) && css.changed)}>
      <Row
        id={`achievement-row-1-${index}`}
      >
        <Col lg={2}>
          <AchievementField
            id={`[${index}].name`}
            label="Achievement Name"
            placeholder="Achievement Name"
          />
        </Col>
        <Col lg={2}>
          <AchievementWhenField
            rowIndex={index}
          />
        </Col>
        {whenValue?.type === 'memory' && (
          <>
            <Col sm="auto" className="mt-4">
              At least
            </Col>
            <Col lg={2} md={3}>
              <AchievementField
                type="number"
                id={`[${index}].check.value`}
                label="Value"
                placeholder="Value"
                className="position-relative m-0"
                value={checkValue}
              />
              <div className={css.checkWrapper}>
                <Form.Check
                  type="switch"
                  id={`[${index}].isManual`}
                  name={`[${index}].isManual`}
                  label="Manual only"
                  checked={isManual.value}
                  onChange={(e) => isManualHelpers.setValue(e.target.checked)}
                  style={{ transform: 'scale(0.75) translateX(-15%)', width: '135%' }}
                />
              </div>
              {isManual.value && (
                <AchievementField
                  as="select"
                  id={`[${index}].unavailableAfter`}
                  label="Unavailable After"
                  value={value.unavailableAfter ?? ''}
                  title={unavailableAfterTitle}
                >
                  <UnavailableAfterOptions episodes={canBeMissedEpisodes} />
                </AchievementField>
              )}
            </Col>
            <Col md={1}>
              <AchievementField
                as="select"
                id={`[${index}].reward.contentId`}
                label="Reward content"
                value={rewardContentValue}
                title={rewardEpisodes.find((episode) => episode.uuid === value.reward?.contentId)?.title ?? ''}
                onChange={handleRewardContentChange}
              >
                <BonusEpisodeOptions bonusEpisodes={rewardEpisodes} />
              </AchievementField>
            </Col>
            <Col>
              <AchievementField
                id={`[${index}].displayName`}
                label="Display Name"
                placeholder="Display Name"
              />
            </Col>
            <Col md={1}>
              <MemoryIconField
                id={`[${index}].icon`}
                memoryIcons={memoryIcons}
                onSave={createMemoryIcon}
                value={icon.value}
                onChange={handleChange}
                isInvalid={!!errors[index]?.icon}
                errorMessage={errors[index]?.icon}
                variant="outline-primary"
              />
            </Col>
            <Col>
              <AchievementField
                id={`[${index}].description`}
                label="Description"
                placeholder="Description"
              />
            </Col>
          </>
        )}
        {whenValue?.type === 'episode' && (
          <>
            <Col md="auto">
              <Col sm="auto" className="mt-4">
                Is complete
              </Col>
            </Col>
            <Col lg={2} md={3} sm={3} xs={5}>
              <AchievementField
                as="select"
                id={`[${index}].reward.contentId`}
                label="Reward content"
                value={rewardContentValue}
                title={rewardEpisodes.find((episode) => episode.uuid === value.reward?.contentId)?.title ?? ''}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setFieldValue(`[${index}].reward.type`, AchievementRewardType.None);
                  } else {
                    setFieldValue(`[${index}].reward.type`, AchievementRewardType.Episode);
                  }
                  handleChange(e);
                }}
              >
                {rewardEpisodes.length > 0
                  ? (
                    <>
                      <option value="" disabled>[Select]</option>
                      {rewardEpisodes.map((episode) => (
                        <option
                          key={episode.id}
                          value={episode.uuid}
                          title={episode.title}
                        >
                          {episode.title + getEpisodeTitleIcons(episode)}
                        </option>
                      ))}
                    </>
                  )
                  : <option value="" disabled>None available</option>}
              </AchievementField>
            </Col>
            <Col xs="auto">
              <AchievementField
                style={{ maxWidth: '65px' }}
                id={`[${index}].reward.delaySec`}
                label="Time Delay"
                placeholder="Enter"
                className="position-relative m-0"
                value={grantDelaySecondsValue / 3600}
                onChange={(e) => {
                  const isNumber = /^[0-9]*$/.test(e.target.value);
                  if (isNumber) {
                    setFieldValue(`[${index}].reward.delaySec`, Number(e.target.value) * 3600);
                  }
                }}
              />
            </Col>
            <Col xs="auto" className={css.grantDelaySecondsText}>
              hrs
            </Col>
            <Col>
              {/* EMPTY SPACE */}
            </Col>
          </>
        )}
        {!whenValue?.type && (
          <Col>
            <div className="text-center">Please select a When option</div>
          </Col>
        )}
        <Col sm="auto" className="my-auto">
          {isDeleteDisabled && (
            <div className="position-absolute" style={{ top: '-1rem', right: '0' }}>
              <BadgeWithTooltip
                id={`delete-button_${index}`}
                title="Can't be deleted"
                content={getAchievementUsedTooltipContent(value.usedInStory, value.isOwnedByUser)}
              />
            </div>
          )}
          <Button
            onClick={handleDeleteButtonClick}
            variant="secondary"
            disabled={isDeleteDisabled}
          >
            X
          </Button>
        </Col>
      </Row>
      {isSendNotifications && (
        <Row
          id={`achievement-row-2-${index}`}
        >
          <Col lg={2}>
            <AchievementField
              id={`[${index}].notification.title`}
              label="Notification Title"
              placeholder="Default Title"
            />
          </Col>
          <Col>
            <AchievementField
              id={`[${index}].notification.message`}
              label="Notification Message"
              placeholder="Default Message"
            />
          </Col>
        </Row>
      )}
    </div>
  );
}
