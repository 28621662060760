import { CancelTokenSource } from 'axios';

export enum AudioType {
  SFX = 'effect',
  Music = 'music',
}

export type Audio = {
  id: string;
  alias: string;
  title: string;
  type: AudioType;
  userId: number;
  userName?: string;
  duration: number;
  audioName: string;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  audioUrl: string;
  hasBookAccess?: boolean;
  isUsedInBook?: boolean;
}

export type AudioContextType = {
  audios: Audio[];
  isLoading: boolean;
  refetchAudio: (cancelToken: CancelTokenSource) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalCount: number;
  handleSearchSubmit: (searchTerm: string) => void;
  searchTerm: string;
}
