// [libx264 @ 0xe00510] Possible presets: ultrafast superfast veryfast faster fast medium slow slower veryslow placebo
import {
  AudioConfig,
  Libx264VideoCompressionPreset,
  type VideoConfig,
} from '../../dorian-shared/types/media/MediaConfigs';

export const NORMALIZED_VIDEO_SIZE = { width: 576, height: 1024 };
export const NORMALIZED_VIDEO_BG_COLOR = 'black';

export const compileFfmpegAudioParams = (videoConfig: AudioConfig): string[] => {
  const audioFrequency = videoConfig?.audio_frequency ?? '44100';
  const audioTargetBitrate = videoConfig?.audio_target_bitrate ?? '128k';

  return [
    '-err_detect', 'explode',
    '-xerror',
    '-c:a', 'mp3',
    '-b:a', audioTargetBitrate,
    '-ar', audioFrequency,
  ];
};

export const compileFfmpegVideoParams = (videoConfig: VideoConfig): string[] => {
  const audioFrequency = videoConfig?.audio_frequency ?? '44100';
  const audioTargetBitrate = videoConfig?.audio_target_bitrate ?? '128k';
  const videoBufferSize = videoConfig?.video_buffer_size;
  const videoMinBitrate = videoConfig?.video_minrate;
  const videoMaxBitrate = videoConfig?.video_maxrate;
  const videoH264Preset = videoConfig?.video_h264_preset ?? Libx264VideoCompressionPreset.veryslow;
  const videoFrameRate = videoConfig?.video_framerate ?? '30';

  return [
    '-err_detect', 'explode',
    '-xerror',
    '-c:v', 'libx264',
    '-profile:v', 'high',
    '-level:v', '4.0',
    '-pix_fmt', 'yuv420p',
    '-colorspace:v', 'bt709',
    '-color_primaries:v', 'bt709',
    '-color_trc:v', 'bt709',
    '-color_range:v', 'tv',
    '-bsf:v', 'h264_metadata=chroma_sample_loc_type=0',
    '-c:a', 'aac',
    '-b:a', audioTargetBitrate,
    '-ar', audioFrequency,
    '-x264opts', 'opencl', // slight boost
    '-brand', 'mp42', // brand compat
    '-preset', `${videoH264Preset}`, // compression preset
    '-movflags', '+faststart', // ability to start video earlier (streaming?)
    ...(typeof videoMinBitrate === 'string' ? ['-minrate', videoMinBitrate] : []),
    ...(typeof videoMaxBitrate === 'string' ? ['-maxrate', videoMaxBitrate] : []),
    ...(typeof videoBufferSize === 'string' ? ['-bufsize', videoBufferSize] : []),
    '-r', videoFrameRate,
  ];
};
