import { isEmpty } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import {
  PurchaseCurrencyOption,
  RewardPurchaseListing,
} from '../../../../../dorian-shared/types/story/Story';
import { useClientVariables } from '../../../../../hooks/useClientVariables';
import { CurrencySelector } from './CurrencySelector/CurrencySelector';
import { PriceInput } from './PriceInput/PriceInput';

type CurrencyOverrideFormGroupProps = {
  value: RewardPurchaseListing | null;
  onChange: (value: RewardPurchaseListing | null) => void;
};

export function CurrencyOverrideFormGroup({
  value,
  onChange,
}: CurrencyOverrideFormGroupProps) {
  const [defaultClientVariablePrice] = useClientVariables('pay_to_access_default_price');

  const defaultPrice = Number.isNaN(defaultClientVariablePrice) ? 25 : Number(defaultClientVariablePrice);
  const isOverrideEnabled = !isEmpty(value);

  const currentPrice = useMemo(() => value?.costInHearts ?? defaultPrice, [defaultPrice, value?.costInHearts]);
  const currentCurrency = useMemo(
    () => value?.currency ?? PurchaseCurrencyOption.HeartsOnly,
    [value],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        onChange({
          currency: currentCurrency,
          costInHearts: currentPrice,
        });
      } else {
        onChange(null);
      }
    },
    [onChange, currentCurrency, currentPrice],
  );

  const handlePriceChange = useCallback(
    (newPrice: number) => {
      if (!isOverrideEnabled) return;

      onChange({
        currency: currentCurrency,
        costInHearts: newPrice,
      });
    },
    [onChange, currentCurrency, isOverrideEnabled],
  );

  const handleCurrencyChange = useCallback(
    (newCurrency: PurchaseCurrencyOption) => {
      if (!isOverrideEnabled) return;

      onChange({
        currency: newCurrency,
        costInHearts: currentPrice,
      });
    },
    [onChange, currentPrice, isOverrideEnabled],
  );

  return (
    <>
      <Col sm="auto">
        <Form.Check
          custom
          type="checkbox"
          id="isOverrideCurrencyEnabled"
          name="isOverrideCurrencyEnabled"
          label="Allow paid override"
          checked={isOverrideEnabled}
          onChange={handleCheckboxChange}
        />
      </Col>
      {isOverrideEnabled && (
        <>
          <Col sm={4}>
            <PriceInput value={currentPrice} onChange={handlePriceChange} />
          </Col>
          <Col>
            <CurrencySelector
              value={currentCurrency}
              onChange={handleCurrencyChange}
            />
          </Col>
        </>
      )}
    </>
  );
}
