import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { DebouncedSearch } from '../../../ui/DebouncedSearch/DebouncedSearch';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { useAudios } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/useAudios';
import classNames from './AudioPicker.module.scss';
import { AudiosBasicWithPick } from './AudiosBasicWithPick/AudiosBasicWithPick';

const audioPlayer = new Audio();

export function AudioPicker({
  title,
  initialValue,
  disabled,
  onConfirm,
}) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(initialValue);

  const handleSaveClick = () => {
    setIsModalShown(false);
    onConfirm(selectedAudio);
  };

  const {
    audios,
    isLoading,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
    currentPageSize,
  } = useAudios(10);

  const handleCancelClick = () => setIsModalShown(false);

  const handleAudioClick = (audio) => setSelectedAudio(audio);

  useEffect(() => {
    if (isModalShown && selectedAudio) {
      audioPlayer.pause();
      audioPlayer.src = selectedAudio.audioUrl;
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }

    return () => {
      audioPlayer.pause();
    };
  }, [isModalShown, selectedAudio]);

  return (
    <>
      <Button
        size="sm"
        className={classNames.buttonExternal}
        disabled={disabled}
        variant={disabled ? 'outline-secondary' : 'secondary'}
        onClick={() => setIsModalShown(true)}
      >
        Choose audio
      </Button>
      <Modal
        className={classNames.modal}
        size="lg"
        show={isModalShown}
        onHide={() => setIsModalShown(false)}
        backdrop="static"
      >
        <Modal.Title className={classNames.modalTitle}>
          {title}
        </Modal.Title>
        <Modal.Body className={classNames.modalBody}>
          <Row>
            <Col md={12}>
              {isLoading && (
              <Spinner
                className={classNames.spinner}
                variant="primary"
                animation="border"
                size="lg"
              />
              )}
              <DebouncedSearch
                initialValue={searchTerm}
                onSearchSubmit={handleSearchSubmit}
              />
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={currentPageSize}
                onPageChange={setCurrentPage}
              />
              <AudiosBasicWithPick
                audios={audios}
                selectedId={selectedAudio?.id}
                onClick={handleAudioClick}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={classNames.modalButtons}>
            <Button
              className={classNames.button}
              onClick={handleCancelClick}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className={classNames.button}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
