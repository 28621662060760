import { User, UserAction } from '../../dorian-shared/types/user/User';
import { auth } from '../Auth/Auth';

export function isAdminRole(user?: User | null) {
  const checkUser = user || auth.getUser();
  return checkUser?.role === 'admin';
}

export function isUserActionEnabled(user: User | undefined, action: UserAction) {
  const authUser = auth.getUser();

  const checkUser = user || authUser;

  if (isAdminRole(checkUser)) {
    return true;
  }
  return !!checkUser?.actions.includes(action);
}

export function isMemoryBankEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.ChoiceMemory);
}

export function isAchievementsEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.Achievements);
}

export function isVideosEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.Videos);
}

export function isCharactersEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.Characters);
}

export function isBackgroundsEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.Backgrounds);
}

export function isAudiosEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.Audios);
}

export function isABTestEnabled(user?: User) {
  return isUserActionEnabled(user, UserAction.ABTest);
}
