import React, { useState } from 'react';
import {
  Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../../api';
import classNames from './DeleteAudioModal.module.scss';

export function DeleteAudioModal({
  audio,
  onDelete,
  onCancel,
}) {
  const useDeleteAudio = (onDeleteAudio) => {
    const [isLoading, setIsLoading] = useState(false);

    const deleteAudio = () => {
      setIsLoading(true);
      api.delete(`/v1/audio/${audio.id}`)
        .then(() => {
          onDeleteAudio();
        })
        .catch((e) => {
        })
        .finally(() => setIsLoading(false));
    };
    return { deleteAudio, isLoading };
  };

  const { deleteAudio, isLoading } = useDeleteAudio(onDelete);

  const handleCancelClick = () => onCancel(audio);

  return (
    <Modal
      show
      size="md"
    >
      <Modal.Header className={classNames.modalHeader}>
        <Modal.Title className={classNames.modalTitle}>
          Delete audio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classNames.modalTitle}>
        <Row className={classNames.audioTitle}>
          <Col>
            {audio.title}
          </Col>
        </Row>
        <Row className={classNames.audioText}>
          <Col>
            Do you really want to delete this audio?
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            type="reset"
            variant="secondary"
            className={classNames.button}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className={classNames.button}
            onClick={deleteAudio}
          >
            {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
