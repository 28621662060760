import React, { useEffect, useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import musicNoteIcon from '../../../assets/images/ui/music-note.svg';
import { AudioType } from '../../../dorian-shared/types/audio/audio';
import {
  AudioStepAction,
  AudioStepSettingsType,
  MusicActionSettings,
  SFXActionSettings,
} from '../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudioMusicContainer } from './AudioMusicContainer/AudioMusicContainer';
import { AudioSFXContainer } from './AudioSFXContainer/AudioSFXContainer';
import classes from './AudioStepSettingsModal.module.scss';

export interface AudioStepSettingsModalProps {
  stepIndex: number;
  value: AudioStepSettingsType;
  onChange: (value: AudioStepSettingsType) => void;
  onHide: () => void;
  disabled?: boolean;
}

export function AudioStepSettingsModal(props: AudioStepSettingsModalProps) {
  const { stepIndex, value, disabled } = props;
  const [show, setShow] = useState(false);
  const [audioStepSettings, setAudioStepSettings] = useState<AudioStepSettingsType>(value);

  useEffect(() => {
    setAudioStepSettings(value);
  }, [value]);

  const handleOnHide = () => {
    const { onHide } = props;
    setShow(false);
    setAudioStepSettings(value);
    onHide?.();
  };

  const handleSave = () => {
    const { onChange } = props;
    onChange?.(audioStepSettings);
    handleOnHide();
  };

  const handleAudioChange = (rowIndex: number, settings: SFXActionSettings | MusicActionSettings) => {
    const newAudioStepSettings = [...audioStepSettings];
    newAudioStepSettings[rowIndex] = settings;
    setAudioStepSettings(newAudioStepSettings);
  };

  const fallbackSFXValue: SFXActionSettings = {
    type: AudioType.SFX,
    action: AudioStepAction.None,
    audioId: null,
    audioUrl: '',
    audioSettings: null,
  };

  const fallbackMusicValue: MusicActionSettings = {
    type: AudioType.Music,
    action: AudioStepAction.None,
    audioId: null,
    audioUrl: '',
    audioSettings: {
      loop: 0,
    },
  };

  const isSFXExists = audioStepSettings.some((action) => action?.type === AudioType.SFX);
  if (!isSFXExists) {
    audioStepSettings.push(fallbackSFXValue);
  }
  const isMusicExists = audioStepSettings.some((action) => action?.type === AudioType.Music);
  if (!isMusicExists) {
    audioStepSettings.push(fallbackMusicValue);
  }

  const sfxIndex = audioStepSettings.findIndex((action) => action?.type === AudioType.SFX);
  const musicIndex = audioStepSettings.findIndex((action) => action?.type === AudioType.Music);

  const audioCount = value.filter((a) => a?.action && a.action !== AudioStepAction.None).length;

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="hidden"
        name={`steps[${stepIndex}][stepAudios]`}
        value={JSON.stringify(audioStepSettings.filter((a) => a && a.action !== AudioStepAction.None))}
      />
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setShow(!show)}
      >
        <img
          src={musicNoteIcon}
          alt="Music note"
          style={{
            width: '18px',
            height: '16px',
          }}
        />
      </Button>
      {audioCount > 0 && (
        <span className="boxBadge" style={{ top: '-8px', right: '-8px' }}>
          <Badge
            pill
            variant="indicator"
          >
            {audioCount}
          </Badge>
        </span>
      )}
      <Modal
        show={show}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName={classes.contentClassName}
        dialogClassName={classes.dialogClassName}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex">
            Audio Step Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AudioSFXContainer
            value={audioStepSettings[sfxIndex] as SFXActionSettings ?? fallbackSFXValue}
            onChange={(settings) => handleAudioChange(sfxIndex, settings)}
            disabled={disabled}
          />
          <AudioMusicContainer
            value={audioStepSettings[musicIndex] as MusicActionSettings ?? fallbackMusicValue}
            onChange={(settings) => handleAudioChange(musicIndex, settings)}
            disabled={disabled}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleOnHide}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={disabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
