import { BookLocation } from '../../../../dorian-shared/types/bookLocation/bookLocation';

export const useScenePreview = (scenes: BookLocation[], id?: number) => {
  const scene = id
    ? scenes.find((object) => Number(object.id) === Number(id))
    : scenes.find((object) => object.isDefault);

  return {
    hasVideo: scene?.video?.videoUrl,
    imageUrl: scene?.image?.imageUrl,
    videoUrl: scene?.video?.videoUrl,
    videoSettings: scene?.videoSettings,
    title: scene?.title,
  };
};
