import React from 'react';
import { Form } from 'react-bootstrap';

export function SnapToGridCheckBox({ item }) {
  return (
    <div className="pt-5 pb-3">
      <Form.Group controlId={item.variant}>
        <Form.Check
          custom
          id={item.id}
          disabled={item.disabled}
          defaultValue={item.value}
          checked={item.value}
          onChange={item.action}
          type="checkbox"
          label={item.title}
        />
      </Form.Group>
    </div>
  );
}
