import React, { useContext } from 'react';
import { Alert, Col, Row, Form } from 'react-bootstrap';
import { AudioContextType } from '../../../../dorian-shared/types/audio/audio';
import {
  AudioStepAction,
  SFXActionSettings,
} from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { AudioPlay } from '../AudioPlay/AudioPlay';
import { AudioSFXSelect } from '../AudioSFXSelect/AudioSFXSelect';
import RangeSlider from 'react-bootstrap-range-slider';
import classes from '../AudioStepSettingsModal.module.scss';

type SFXContainerProps = {
  value: SFXActionSettings,
  onChange: (value: SFXActionSettings) => void,
  disabled?: boolean,
}

export function AudioSFXContainer(props: SFXContainerProps) {
  const { value, onChange, disabled } = props;

  const { audios } = useContext<AudioContextType>(AudiosContext);
  const currentAudio = audios.find((audio) => audio.id === value.audioId);

  const isDisabled = value.action === AudioStepAction.None
    || value.action === AudioStepAction.Stop
    || value.audioId === null;

  const createRangeTooltip = (value:number) => `${value * 100}%`;

  return (
    <>
      <Alert variant="info" className="text-center p-0 mb-2">
        Sound Effect
      </Alert>
      <Row>
        <Col>
          <AudioSFXSelect
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </Col>
        <Col sm="auto">
          <AudioPlay
            url={currentAudio?.audioUrl ?? ''}
            settings={{ 
              volume: value.audioSettings?.volume ?? 1
            }}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-1">
          <div className={classes.volumeField}>
            <RangeSlider
              disabled={isDisabled || disabled}
              value={value.audioSettings?.volume ?? 1}
              min={0}
              max={1}
              step={0.1}
              size="sm"
              tooltipLabel={createRangeTooltip}
              onChange={(e) => {
                const newValue = { ...value };
                if (!newValue.audioSettings) {
                  newValue.audioSettings = {};
                }
                newValue.audioSettings.volume = Number(e.target.value);
                onChange(newValue);
              }}
            />
            <Form.Label className={classes.volumeLabel}>
              Volume
            </Form.Label>
          </div>
        </Col>
      </Row>
    </>
  );
}
