import { AudioType, Audio } from './audio';

export enum AudioStepAction {
  None = 'none',
  Play = 'play',
  Stop = 'stop',
}

export type AudioSelectOption = {
  action: AudioStepAction;
  type: AudioType;
  audioUrl: string
  value: string
  label: string;
}

export type SFXActionSettings = {
  type: AudioType.SFX;
  action: AudioStepAction;
  audioId: string | null;
  audioUrl: string;
  audioSettings: {
    volume?: number;
  } | null;
  Audio?: Audio;
}

export type MusicActionSettings = {
  type: AudioType.Music;
  action: AudioStepAction;
  audioId: string | null;
  audioUrl: string;
  audioSettings: {
    volume?: number;
    loop?: number;
  } | null;
  Audio?: Audio;
}

export type AudioStepSettingsType = Array<SFXActionSettings | MusicActionSettings>;
