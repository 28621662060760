import { Audio, AudioType } from '../../../../dorian-shared/types/audio/audio';
import { SFXActionSettings, MusicActionSettings } from '../../../../dorian-shared/types/audio/AudioStepSettingsType';

let currentMusic: HTMLAudioElement | null = null;
let currentSfx: HTMLAudioElement | null = null;
let canPlayAudio: boolean = false;
let isAudioMuted: boolean = false;

export function muteAudio(mute: boolean): void {
  isAudioMuted = mute;
}

export function allowAudio(allow: boolean): void {
  canPlayAudio = allow;
}

export function processAudios(stepAudios: (SFXActionSettings | MusicActionSettings)[] | null | undefined): void {
  if (!Array.isArray(stepAudios)) return;

  for (const stepAudio of stepAudios) {
    if (stepAudio.type === AudioType.SFX) {
      const sfxAudio = stepAudio as SFXActionSettings;
      const audio = sfxAudio.Audio as Audio;

      if (sfxAudio.action === "stop") {
        stopSfx();
      } else if (sfxAudio.action === "play" && audio?.audioName) {
        const audioUrl = `${audio.audioUrl}${audio.userId}/${audio.type}/${audio.audioName}`;
        playSfx(audioUrl, sfxAudio.audioSettings);
      }
    } else if (stepAudio.type === AudioType.Music) {
      const musicAudio = stepAudio as MusicActionSettings;
      const audio = musicAudio.Audio as Audio;

      if (musicAudio.action === "stop") {
        stopMusic();
      } else if (musicAudio.action === "play" && audio?.audioName) {
        const audioUrl = `${audio.audioUrl}${audio.userId}/${audio.type}/${audio.audioName}`;
        playMusic(audioUrl, musicAudio.audioSettings);
      }
    }
  }
}

/**
 * Play a music track
 * @param src - The audio file URL
 */
export function playMusic(src: string, audioSettings: MusicActionSettings["audioSettings"]): void {
  if (!canPlayAudio || isAudioMuted) { return; }
  stopMusic();
  currentMusic = new window.Audio(src);
  currentMusic.loop = audioSettings?.loop === -1 || false;
  currentMusic.volume = audioSettings?.volume ?? 1;
  currentMusic.play().catch(console.error);

  // Reset `currentMusic` when it ends (if loop is false)
  currentMusic.onended = () => {
    currentMusic = null;
  };
}

/**
 * Play a sound effect (SFX)
 * @param src - The audio file URL
 */
export function playSfx(src: string, audioSettings: SFXActionSettings["audioSettings"]): void {
  if (!canPlayAudio || isAudioMuted) { return; }
  stopSfx();
  currentSfx = new window.Audio(src);
  currentSfx.volume = audioSettings?.volume ?? 1;
  currentSfx.play().catch(console.error);

  // Reset `currentSfx` when it ends
  currentSfx.onended = () => {
    currentSfx = null;
  };
}

/**
 * Stop all playing audio (music & SFX)
 */
export function stopAllAudio(): void {
  stopMusic();
  stopSfx();
}

/**
 * Stop currently playing music
 */
export function stopMusic(): void {
  if (currentMusic) {
    currentMusic.pause();
    currentMusic.currentTime = 0;
    currentMusic = null;
  }
}

/**
 * Stop currently playing sound effects
 */
export function stopSfx(): void {
  if (currentSfx) {
    currentSfx.pause();
    currentSfx.currentTime = 0;
    currentSfx = null;
  }
}
