import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAudioPlayer } from '../../useAudioPlayer';
import { Audio } from '../Audio/Audio';
import classNames from './Audios.module.scss';
import { listColumnsSize, listColumnsSizeAdmin } from './constants';

export function Audios({
  audios,
  user,
  onChange,
}) {
  const [playAudioId, setPlayAudioId] = React.useState(null);

  const currentAudio = audios.find((audio) => audio.id === playAudioId);

  const {
    isPlayingAudio,
    playAudio,
    stopPlayingAudio,
  } = useAudioPlayer(currentAudio?.audioUrl);

  const handleTogglePlay = (audioId) => {
    const willStepPlayingCurrentAudio = isPlayingAudio && audioId === playAudioId;
    if (willStepPlayingCurrentAudio) {
      stopPlayingAudio();
      return;
    }
    setPlayAudioId(audioId);
    playAudio();
  };

  let isAdmin = user.role === 'admin';
  let columnSize = isAdmin ? listColumnsSizeAdmin : listColumnsSize;

  return (
    <>
      <Row className={classNames.listTile}>
        <Col sm={columnSize.title} className={classNames.listTileColumn}>
          Title / Path
        </Col>
        <Col sm={columnSize.duration} className={classNames.listTileColumn}>
          Duration
        </Col>
        <Col sm={columnSize.type} className={classNames.listTileColumn}>
          Type
        </Col>
        {isAdmin && (
        <Col sm={columnSize.owner} className={classNames.listTileColumn}>
          Owner
        </Col>
        )}
        <Col sm={columnSize.actions} className={classNames.listTileColumn}>
          Actions
        </Col>
      </Row>
      <ul className={classNames.list}>
        {
        audios.map((audio) => (
          <li
            key={audio.id}
            className={classNames.listItem}
          >
            <Audio
              key={audio.id}
              audio={audio}
              user={user}
              onChange={onChange}
              onTogglePlay={() => handleTogglePlay(audio.id)}
              isPlaying={audio.id === playAudioId && isPlayingAudio}
            />
          </li>
        ))
      }
      </ul>
    </>
  );
}
