import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Story } from '../../../../dorian-shared/types/story/Story';
import css from './RecentEpisode.module.scss';

export function RecentEpisode(props: { recentEpisode: Story }) {
  const { recentEpisode } = props;

  if (!recentEpisode) {
    return null;
  }

  return (
    <Row className={css.container}>
      <Col sm={12} md="auto">
        Jump to most recent:
      </Col>
      <Col>
        <Button
          as={Link}
          to={`/stories/${recentEpisode.id}/branches`}
          variant="asLinkV2"
          className={css.button}
        >
          {`${recentEpisode.book.title} - ${recentEpisode.title}`}
        </Button>
      </Col>
    </Row>
  );
}
