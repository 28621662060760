import React, { useContext, ChangeEvent } from 'react';
import {
  Alert, Col, Form, Row
} from 'react-bootstrap';
import { AudioContextType } from '../../../../dorian-shared/types/audio/audio';
import { AudioStepAction, MusicActionSettings } from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { AudioMusicSelect } from '../AudioMusicSelect/AudioMusicSelect';
import { AudioPlay } from '../AudioPlay/AudioPlay';
import RangeSlider from 'react-bootstrap-range-slider';
import classes from '../AudioStepSettingsModal.module.scss';

type MusicContainerProps = {
  value: MusicActionSettings,
  onChange: (value: MusicActionSettings) => void,
  disabled?: boolean,
}

export function AudioMusicContainer(props: MusicContainerProps) {
  const { value, onChange, disabled } = props;

  const { audios } = useContext<AudioContextType>(AudiosContext);
  const currentAudio = audios.find((audio) => audio.id === value.audioId);

  const isDisabled = value.action === AudioStepAction.None
    || value.action === AudioStepAction.Stop
    || value.audioId === null;

   const createRangeTooltip = (value:number) => `${value * 100}%`;

  return (
    <>
      <Alert variant="info" className="text-center p-0 mt-4 mb-2">
        Background Music
      </Alert>
      <Row>
        <Col>
          <AudioMusicSelect
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </Col>
        <Col sm="auto">
          <AudioPlay
            className="h-100"
            url={currentAudio?.audioUrl ?? ''}
            settings={{ 
              loop: value.audioSettings?.loop ?? 0,
              volume: value.audioSettings?.volume ?? 1
            }}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-1">
          <div className={classes.volumeField}>
            <RangeSlider
              disabled={isDisabled || disabled}
              value={value.audioSettings?.volume ?? 1}
              min={0}
              max={1}
              step={0.1}
              size="sm"
              tooltipLabel={createRangeTooltip}
              onChange={(e) => {
                const newValue = { ...value };
                if (!newValue.audioSettings) {
                  newValue.audioSettings = {};
                }
                newValue.audioSettings.volume = Number(e.target.value);
                onChange(newValue);
              }}
            />
            <Form.Label className={classes.volumeLabel}>
              Volume
            </Form.Label>
          </div>
        </Col>
        <Col className="mt-1">
          <Form.Check
            custom
            id="audio-music-loop"
            label="Loop"
            checked={!!(value.audioSettings?.loop && value.audioSettings.loop !== 0)}
            onChange={(e) => {
              const newValue = { ...value };
              if (!newValue.audioSettings) {
                newValue.audioSettings = {};
              }
              newValue.audioSettings.loop = e.target.checked ? -1 : 0;
              onChange(newValue);
            }}
            disabled={isDisabled || disabled}
          />
        </Col>
      </Row>
    </>
  );
}
