import queryString from 'query-string';
import React, { Component } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { api } from '../../../api';
import { Paginator } from '../../../ui/Paginator';
import { DefaultFallback } from '../../ErrorBoundary/FallBacks/DefaultFallback';
import '../News.scss';
import { NewsItem } from './NewsItem/NewsItem';

export class NewsFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      currentPage: 1,
      data: [],
    };
    const { auth } = props;
    this.user = auth.getUser();
  }

  componentDidMount() {
    this.loadData(0);
  }

  componentDidUpdate(prevProps) {
    const { search, type, location } = this.props;

    if ((type && type === 'books' && (search !== prevProps.search))
      || (location && location.search && (location.search !== prevProps.location.search))
    ) {
      this.loadData(0);
    }
  }

  loadData = (offset, number) => {
    const {
      limit, obj, search, location, type,
    } = this.props;
    const { currentPage } = this.state;

    const values = queryString.parse(location.search);

    const params = {
      order: 'promptPriority:desc,createdAt:desc',
      limit,
      offset: offset || 0,
    };
    let url = '';

    if (type && type === 'prompts') {
      params.activeContest = 1;
      url = '/v1/bookprompts';
    }

    if (type && type === 'trending') {
      params.trending = 1;
      url = '/v1/bookprompts';
    }

    if (type && type === 'books') {
      params.groupId = (obj && obj.id) && obj.id;
      params.author = this.user.id;
      url = '/v1/books/';
      if (search) {
        params.search = search;
      }
    }

    if (values && values.story_list && values.story_list !== 'all') {
      params.story_list = values.story_list;
    }

    api.get(url, {
      params,
    }).then((res) => {
      if (currentPage !== 1 && (Math.ceil(res.data.totalCount / limit) - currentPage) < 0) {
        this.setState({
          currentPage: 1,
          totalCount: res.data.totalCount,
        }, () => {
          this.loadData(0);
        });
      } else {
        this.setState({
          data: res.data[type === 'trending' ? 'prompts' : type],
          currentPage: number || 1,
          totalCount: res.data.totalCount,
        });
      }
    });
  };

  render() {
    const { limit, type } = this.props;
    const { currentPage, totalCount, data } = this.state;

    if (!data || data.length < 1) {
      return (
        <Alert variant="light" className="text-center">
          Create your first game using one of our templates below!
        </Alert>
      );
    }

    const { ErrorBoundary } = bugTracker();

    return (
      <div data-testid={`news-frame-${type}`}>

        <Row>
          {data.map((item) => (
            <Col key={item.id} md={6} sm={6} xs={12} className="pb-4">
              <ErrorBoundary fallback={DefaultFallback}>
                <NewsItem
                  data={item}
                  {...this.props}
                />
              </ErrorBoundary>
            </Col>
          ))}
        </Row>
        <Paginator
          limit={limit}
          totalCount={totalCount}
          current_page={currentPage}
          update={(val, number) => this.loadData(val, number)}
        />

      </div>
    );
  }
}
