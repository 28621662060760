import {
  useEffect, useRef, useState, useCallback,
} from 'react';

export function useAudioPlayer(audioURL, audioSettings) {
  const audioPlayer = useRef(new Audio());

  const [audioVolume, setAudioVolume] = useState(audioSettings?.volume ?? 1);
  const [isLoopAudio, setIsLoopAudio] = useState(audioSettings?.loop ?? false);

  const [isPlayingAudio, setIsPlayingAudio] = useState(audioSettings?.play ?? false);

  const playAudio = useCallback(() => setIsPlayingAudio(true), []);

  const stopPlayingAudio = useCallback(() => setIsPlayingAudio(false), []);

  const togglePlayAudio = useCallback(() => setIsPlayingAudio((oldValue) => !oldValue), []);

  useEffect(() => {
    audioPlayer.current.volume = audioVolume;
  }, [audioVolume]);

  useEffect(() => {
    const player = audioPlayer.current;
    player.pause();
    if (isPlayingAudio && audioURL) {
      player.src = audioURL;
      player.play();
      player.onended = togglePlayAudio;
    }
    return () => {
      player.pause();
    };
  }, [isPlayingAudio, audioURL]);

  useEffect(() => {
    audioPlayer.current.loop = isLoopAudio;
  }, [isLoopAudio]);

  useEffect(() => {
    setIsLoopAudio(audioSettings?.loop ?? false);
  }, [audioSettings?.loop]);

  useEffect(() => {
    setAudioVolume(audioSettings?.volume ?? 1);
    if (audioPlayer.current) {
      audioPlayer.current.volume = audioSettings?.volume ?? 1;
    }
  }, [audioSettings?.volume]);

  return {
    audioVolume,
    setAudioVolume,
    isLoopAudio,
    setIsLoopAudio,
    isPlayingAudio,
    togglePlayAudio,
    playAudio,
    stopPlayingAudio,
  };
}
