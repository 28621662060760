import React from 'react';
import DropdownToggle, { DropdownToggleProps } from 'react-bootstrap/DropdownToggle';
import css from './DropdownMenuToggle.module.scss';

export function DropdownMenuToggle(props: DropdownToggleProps) {
  const {
    variant = 'menu-v2',
    className = css.dropdownMenuToggle,
    size = 'sm',
    children = <span>&#8942;</span>,
    ...rest
  } = props;

  return (
    <DropdownToggle
      variant={variant}
      className={className}
      size={size}
      {...rest}
    >
      {children}
    </DropdownToggle>
  );
}
