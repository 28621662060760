import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { VideoSettings } from 'dorian-shared/types/video/video';
import { VideoPlayer } from '../../StoryBranches/BranchNodeCards/VideoPlayer';

interface VideoData {
  id?: number;
  title: string;
  videoUrl: string;
}

interface VideoModalProps {
  video: VideoData;
  videoSettings?: VideoSettings;
  isVisible: boolean;
  onClose: () => void;
}

/**
 * A reusable modal component for displaying videos
 *
 * @param video - The video data object containing title and videoUrl
 * @param videoSettings - The video settings object containing video settings
 * @param isVisible - Boolean to control modal visibility
 * @param onClose - Function to call when modal is closed
 */
export function VideoModal({
  video,
  videoSettings,
  isVisible = true,
  onClose,
}: VideoModalProps) {
  if (!video) return null;
  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{video.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0" style={{ height: '300px' }}>
        <VideoPlayer
          videoUrl={video.videoUrl}
          videoTitle={video.title}
          isPlaying={isVisible}
          videoSettings={videoSettings}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
