import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import css from './NewsFrameTitle.module.scss';

type NewsItemTitleProps = {
  title: string,
  subTitle?: string,
  linkTitle?: string
  linkHref?: string
}

export function NewsFrameTitle(props: NewsItemTitleProps) {
  const {
    title, subTitle, linkTitle, linkHref,
  } = props;
  return (
    <div className={css.container}>
      <Row>
        <Col>
          <h2 className={css.title}>{title || ''}</h2>
        </Col>
        {linkTitle && (
        <Col xs="auto" className={css.link}>
          <Button
            as={Link}
            to={linkHref || ''}
            variant="asLinkV2"
          >
            {linkTitle}
          </Button>
        </Col>
        )}
      </Row>
      {subTitle && (
        <Row>
          <Col className={css.subtitle}>
            {subTitle}
          </Col>
        </Row>
      )}
    </div>
  );
}
