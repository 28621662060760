import { Audio, AudioType } from '../../../dorian-shared/types/audio/audio';
import {
  AudioSelectOption,
  AudioStepAction,
} from '../../../dorian-shared/types/audio/AudioStepSettingsType';

export const actionSFXOptions: AudioSelectOption[] = [
  {
    type: AudioType.SFX,
    action: AudioStepAction.None,
    audioUrl: '',
    value: AudioStepAction.None,
    label: 'None',
  },
  {
    type: AudioType.SFX,
    action: AudioStepAction.Stop,
    audioUrl: '',
    value: AudioStepAction.Stop,
    label: 'Stop Playing',
  },
];

export const actionMusicOptions: AudioSelectOption[] = [
  {
    type: AudioType.Music,
    action: AudioStepAction.None,
    audioUrl: '',
    value: AudioStepAction.None,
    label: 'No changes',
  },
  {
    type: AudioType.Music,
    action: AudioStepAction.Stop,
    audioUrl: '',
    value: AudioStepAction.Stop,
    label: 'Stop Playing',
  },
];

export function getAudioOptions(audios: Audio[], type: AudioType): AudioSelectOption[] {
  return audios.filter((audio: Audio) => audio.type === type)
    .map((audio: Audio) => ({
      action: AudioStepAction.Play,
      type,
      audioUrl: audio.audioUrl,
      value: audio.id,
      label: audio.title,
      ownerId: audio.userId,
      ownerName: audio.userName,
      hasBookAccess: audio.hasBookAccess,
      isUsedInBook: audio.isUsedInBook
    }));
}
