import React from 'react';
import { Button } from 'react-bootstrap';

type ButtonWithIconProps = {
  title: string;
  icon: string;
  onClick: () => void;
  variant: string;
  disabled?: boolean;
  className?: string;
  size?: 'sm' | 'lg';
}

export function ButtonWithIcon(props: ButtonWithIconProps) {
  const {
    icon,
    title,
    onClick,
    variant = 'primary-v2',
    disabled,
    className,
    size = 'lg',
  } = props;

  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={className}
      size={size}
    >
      <div className="d-flex align-items-center">
        <img
          src={icon}
          alt={title}
          style={{ width: '20px', height: '20px' }}
        />
        <div className="ml-1">
          {title}
        </div>
      </div>
    </Button>
  );
}
