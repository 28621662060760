import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { api } from '../../../../api';

export function useAudios(initialPageSize, authorId, bookId, triggerRefresh) {
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [audios, setAudios] = useState([]);

  const [currentPageSize, setCurrentPageSize] = useState(initialPageSize);

  const refetchAudio = useCallback(
    (cancelToken) => {
      const params = {
        search: searchTerm || undefined,
        order: 'createdAt:desc',
      };

      if (currentPageSize) {
        params.limit = currentPageSize;
        params.offset = currentPageSize * (currentPage - 1);
      }

      if (authorId) {
        params.authorId = authorId;
      }
      if (bookId) {
        params.bookId = bookId;
      }

      setIsLoading(true);

      api.get('/v1/audio', {
        params,
        cancelToken: cancelToken ? cancelToken.token : undefined,
      })
        .then((res) => {
          const { audios: audiosData } = res.data;
          setTotalCount(res.data.totalCount);
          setAudios(audiosData);
        })
        .finally(() => setIsLoading(false));
    },
    [currentPage, currentPageSize, searchTerm],
  );

  const updateAudio = useCallback(
    async (audioId, updateData, cancelToken) => {
      const params = {
      };

      if (bookId) {
        params.bookId = bookId;
      }

      try {
        const response = await api.put(`/v1/audio/${audioId}`, updateData, {
          params,
          cancelToken: cancelToken ? cancelToken.token : undefined,
        });
        return response.data;
      } catch (error) {
        console.error('Error updating audio:', error);
        throw error;
      }
    },
    []
  );
  
  useEffect(() => {
    setCurrentPageSize(initialPageSize);
  }, [initialPageSize]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    refetchAudio(cancelToken);
    return () => {
      cancelToken.cancel('AudioLibrary unmount');
    };
  }, [refetchAudio, triggerRefresh]);

  const handleSearchSubmit = (newSearchTerm) => {
    setCurrentPage(1);
    setSearchTerm(newSearchTerm);
  };

  return {
    audios,
    isLoading,
    refetchAudio,
    updateAudio,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
    currentPageSize,
    setCurrentPageSize,
  };
}
