import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { StepTypeMode } from 'dorian-shared/types/branch/Branch';
import {
  CurrencyPreference,
} from '../../../../../../dorian-shared/types/CurrencyPreference/CurrencyPreference';
import { isAdminRole } from '../../../../../utils/userActionUtils';
import { getCurrencyToggleSettingsOptions } from '../utils';

interface AddAnswerFragmentProps {
  stepIndex: number;
  onAddAnswerClick: () => void;
  answersCount: number;
  stepTypeMode: StepTypeMode;
  onStepTypeModeChange: (mode: StepTypeMode) => void;
  competitiveMode: boolean;
  currencyToggleSettings: CurrencyPreference;
  onCurrencyToggleSettingsChange: (currencyToggleSettings: CurrencyPreference) => void;
}

export function AddAnswerHeader(props: AddAnswerFragmentProps) {
  const {
    stepIndex,
    onAddAnswerClick,
    answersCount,
    onStepTypeModeChange,
    stepTypeMode,
    competitiveMode,
    currencyToggleSettings,
    onCurrencyToggleSettingsChange,
  } = props;

  const isAdmin = isAdminRole();

  const currencyToggleSettingsOptions = getCurrencyToggleSettingsOptions();

  const defaultValue = currencyToggleSettingsOptions.find((item) => item.value === currencyToggleSettings) ?? currencyToggleSettingsOptions[0];

  return (
    <>
      {isAdmin && (
        <Form.Group as={Row}>
          <Form.Label column md={1} className="text-right font-weight-bold">
            Currency:
          </Form.Label>
          <Col md={2}>
            <Select
              name={`steps[${stepIndex}][currencyToggleSettings]`}
              classNamePrefix="react-select"
              className="react-select-container"
              defaultValue={defaultValue}
              options={currencyToggleSettingsOptions}
              onChange={(selectedOption) => onCurrencyToggleSettingsChange(selectedOption?.value ?? CurrencyPreference.HeartsFirst)}
            />
          </Col>
        </Form.Group>
      )}
      <Row>
        <Col md="auto" className="mr-3">
          <h5>
            Answers (
            {answersCount}
            )
            <Button
              size="sm"
              variant="secondary"
              className="ml-3"
              onClick={() => onAddAnswerClick()}
              disabled={(answersCount >= 3)}
              onMouseDown={(e) => e.stopPropagation()}
            >
              + Add Answer
            </Button>
          </h5>
        </Col>
        {competitiveMode && (
        <>
          <Col xs="auto" className="mr-3">
            <Form.Check
              custom
              id="regular-branch-type"
              type="radio"
              label="Regular"
              checked={stepTypeMode === StepTypeMode.Regular}
              onChange={() => onStepTypeModeChange(StepTypeMode.Regular)}
            />
          </Col>
          <Col xs="auto" className="mr-3">
            <Form.Check
              custom
              id="eliminationS-branch-type"
              type="radio"
              label="Elimination"
              checked={stepTypeMode === StepTypeMode.Elimination}
              onChange={() => onStepTypeModeChange(StepTypeMode.Elimination)}
            />
          </Col>
        </>
        )}
      </Row>
    </>
  );
}
