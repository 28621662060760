import React, { useCallback, ChangeEvent } from 'react';
import { PurchaseCurrencyOption } from '../../../../../../dorian-shared/types/story/Story';
import { FormControlField } from '../../../../../ui/_fields/FormControlField/FormControlField';
import { CurrencyAllowedOptions } from '../CurrencyAllowedOptions/CurrencyAllowedOptions';

export interface CurrencySelectorProps {
  value: PurchaseCurrencyOption;
  onChange: (value: PurchaseCurrencyOption) => void;
}

export function CurrencySelector({ value, onChange }: CurrencySelectorProps): React.ReactElement {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const currency = (Object.values(PurchaseCurrencyOption).find(
        (option) => Number(option) === Number(event.target.value),
      ) as PurchaseCurrencyOption) ?? PurchaseCurrencyOption.None;

      onChange(currency);
    },
    [onChange],
  );

  return (
    <FormControlField
      as="select"
      id="overrideCurrenciesAllowed"
      label="Currency allowed"
      value={value}
      onChange={handleChange}
      title="overrideCurrenciesAllowed"
      containerClassName="mt-0 p-0"
      controlClassName="py-0"
      size="sm"
      style={{ height: '1.75em' }}
      aria-label="Select currency option"
    >
      <CurrencyAllowedOptions />
    </FormControlField>
  );
}
