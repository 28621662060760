import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Book, BookGroup } from '../../../../../../dorian-shared/types/book/book';
import { auth } from '../../../../../Auth/Auth';
import { DropdownMenuToggle } from '../../../../../uiV2/DropdownMenuToggle/DropdownMenuToggle';
import { PremiumIpDisabledApprovedEdit } from '../../../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../../../utils/premiumIpDisabledEdit';
import '../../../News.scss';

interface NewsItemMenuProps {
  share: (data: Book) => void;
  type: string;
  book: Book;
  group: BookGroup;
  changeGroup: (id: number, groupId: string) => void;
  editBook: (data: Book) => void;
  groups: Array<{
    id: string;
    title: string;
    type: string;
  }>;
}

export function NewsItemMenu(props: NewsItemMenuProps) {
  const {
    share,
    type,
    book,
    changeGroup,
    editBook,
    group,
    groups,
  } = props;
  const user = auth.getUser();

  if (!user) {
    return null;
  }

  const isNotAdminOrOwner = user.role !== 'admin' && book.book_role !== 'owner';

  if (!user || isNotAdminOrOwner || type !== 'books') {
    return null;
  }

  return (
    <Dropdown className="mx-1 newsItemMenu">
      <DropdownMenuToggle />
      <Dropdown.Menu>
        <Dropdown.Item
          as="button"
          onMouseDown={() => {
            editBook(book);
          }}
        >
          Edit
        </Dropdown.Item>

        <Dropdown.Item
          as="button"
          disabled={
            PremiumIpDisabledEdit(user.role, book)
            || PremiumIpDisabledApprovedEdit(user.role, book)
          }
          onMouseDown={() => {
            share(book);
          }}
        >
          Share
        </Dropdown.Item>

        <Dropdown.Divider />

        {groups
          && groups.map(
            (groupItem) => group?.type
              && group.type !== groupItem.type
              && (
                <Dropdown.Item
                  key={groupItem.id}
                  as="button"
                  disabled={
                    PremiumIpDisabledEdit(user.role, book)
                    || PremiumIpDisabledApprovedEdit(user.role, book)
                  }
                  onMouseDown={() => changeGroup(book.id, groupItem.id)}
                >
                  {groupItem.title}
                </Dropdown.Item>
              ),
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
