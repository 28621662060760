import { useContext } from 'react';
import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import { DebouncedSearch } from '../../../ui/DebouncedSearch/DebouncedSearch';
import { Pagination } from '../../../ui/Pagination/Pagination';
import classNames from './AudioLibraryContainer.module.scss';
import { Audios } from './Audios/Audios';
import { AudiosContext, useRefetchAudio } from './AudiosProvider/AudiosProvider';

export function AudioLibraryContainer({ user }) {
  const {
    currentPage,
    setCurrentPage,
    totalCount,
    currentPageSize,

    audios,
    isLoading,

    handleSearchSubmit,
    searchTerm,
  } = useContext(AudiosContext);

  const refetchAudio = useRefetchAudio();

  const handleAudiosOnChange = () => {
    const lastPage = Math.ceil(audios.length / currentPageSize);
    if (lastPage < currentPage) {
      setCurrentPage(lastPage);
    }
    refetchAudio();
  };

  return (
    <>
      {isLoading && (
        <Spinner
          className={classNames.spinner}
          variant="primary"
          animation="border"
          size="lg"
        />
      )}
      <DebouncedSearch
        initialValue={searchTerm}
        onSearchSubmit={handleSearchSubmit}
      />
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={currentPageSize}
        onPageChange={setCurrentPage}
      />
      <Audios
        audios={audios}
        user={user}
        onChange={handleAudiosOnChange}
      />
    </>
  );
}
