import React from 'react';
import noImageIcon from '../../../../assets/images/no_image.svg';
import stepsIcon from '../../../../assets/images/ui/icon-steps.svg';
import textIcon from '../../../../assets/images/ui/icon-text.svg';
import musicNoteIcon from '../../../../assets/images/ui/music-note.svg';
import { VideoThumbnail } from '../../../common/VideoThumbnail/VideoThumbnail';
import { isAudiosEnabled } from '../../../utils/userActionUtils';
import { isCheckStep } from '../BranchEdit/Steps/StepTypeCheck/StepTypeCheck';
import { BranchNodeCardCheckInfo } from './BranchNodeCardCheckInfo';
import { MemoryCards } from './MemoryCards/MemoryCards';
import { TraitImages } from './TraitImages';

export function BranchNodeCard({
  storyGroup,
  branchNode,
  memorySlotData,
}) {
  const isLiveGroup = storyGroup === 'live' || storyGroup === 'liveprompt';
  const isLocationImg = branchNode.location?.image && !isLiveGroup;
  const decisionPoint = branchNode.links?.length > 0;
  const isCheck = isCheckStep(branchNode);
  const audioCount = branchNode.steps.filter((step) => step.stepAudios?.length).length;

  return (
    <div className="card branchNodeCard">
      {branchNode.location?.video?.videoUrl ? (
        <VideoThumbnail
          videoUrl={branchNode.location.video.videoUrl}
          videoSettings={branchNode.location.videoSettings}
          title={branchNode.location_title || 'Video'}
          fallbackImageUrl={isLocationImg ? branchNode.location?.image?.imageUrl : noImageIcon}
        />
      ) : (
        <div
          className="locationImg"
          title={`${branchNode.location_title ?? '-'}`}
          style={{
            backgroundImage: isLocationImg && `url(${branchNode.location?.image?.imageUrl ?? noImageIcon})`,
            backgroundSize: isLocationImg ? 'cover' : 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
      <p className="text-left m-0 pt-0 description descriptionLocationImg">
        {branchNode.description}
      </p>
      {branchNode.type !== 'choice' && (
      <MemoryCards steps={branchNode.steps} />
      )}
      <div className="d-flex w-100 justify-content-around">
        <div className="d-flex" title={`Steps: ${branchNode.steps_count || 0}`}>
          <img src={stepsIcon} alt="steps" />
          <div className="ml-1">
            {`${branchNode.steps_count || 0}`}
          </div>
        </div>
        <div className="d-flex" title={`Words: ${branchNode.words_count || 0}`}>
          <div className="d-flex">
            <img src={textIcon} alt="text" />
            <div className="ml-1">
              {`${branchNode.words_count || 0}`}
            </div>
          </div>
        </div>
        {isAudiosEnabled() && (
          <div className="d-flex" title={`Steps with audios: ${audioCount || 0}`}>
            <div className="d-flex">
              <img
                src={musicNoteIcon}
                alt="text"
                style={{
                  width: '16px',
                  height: '21px',
                }}
              />
              <div className="ml-1">
                {`${audioCount || 0}`}
              </div>
            </div>
          </div>
        )}
      </div>
      {decisionPoint && (
        <TraitImages
          answers={branchNode.answers}
        />
      )}
      {isCheck && (
        <BranchNodeCardCheckInfo
          memorySlotData={memorySlotData}
        />
      )}
    </div>
  );
}
